import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  header: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
  },
  tableHeadText: {
    fontWeight: "600",
  },
  row: {
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      cursor: "pointer",
    },
  },
  ticketNumber: {
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  paginationRoot: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  button: {
    float: "right",
    margin: theme.spacing(4),
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  validation: {
    margin: theme.spacing(1),
  },
  list: {
    width: "100%",
    maxHeight: "500px",
    overflow: "auto",
  },
  listItem: {
    display: "block",
  },
  chip: {
    cursor: "pointer",
    margin: "0 1%",
  },
  card: {
    width: "auto",
    cursor: "pointer",
  },
  // cardContent: {
  //   width: "auto",
  // },
  listIcon: {
    minWidth: "50px",
  },
}));
