import { useState, useEffect } from 'react';
import ValetParkingService from '../../../services/ValetParkingService';
import TicketFieldService from '../../../services/TicketFieldService';
import GuestTypeService from '../../../services/GuestTypeService';
import RateService from '../../../services/RateService';
import VehicleService from '../../../services/VehicleService';
import CountryService from '../../../services/CountryService';
import apiClient from '../../../auth/apiClient';
import { FIELD_ID, FORM_TYPE } from './ArrivalFieldConstants';
import { ENTITY_TYPE, TICKET_FIELD_REQUEST_TYPE } from "../../../constants";

//This hook is used to perform backend activities like fetching data needed to be binded to the form.
export const useValetArrival = (entityID, ticketNumber, parentEntity, triggerEnqueueSnackBar, updateStateValue) => {
    const [ticketGuestType, setTicketGuestType] = useState();
    const [arrivalTicketFields, setArrivalTicketFields] = useState();
    const [guestTypes, setGuestTypes] = useState([]);
    const [rates, setRates] = useState([]);
    const [colors, setColors] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [arrivalDetails, setArrivalDetails] = useState();
    const [formActivityType, setFormActivityType] = useState(FORM_TYPE.CREATE);
    //No data available for this parkedByEmployees.So prepopulating with unassigned
    const [parkedByEmployees] = useState([{ employeeID: 1, employeeName: "unassigned" }]);
    const valetParkingService = new ValetParkingService(apiClient);
    const ticketFieldService = new TicketFieldService(apiClient);
    const guestTypeService = new GuestTypeService(apiClient);
    const rateService = new RateService(apiClient);
    const vehicleService = new VehicleService(apiClient);
    const countryService = new CountryService(apiClient);

    //Fetches the initial details like arrival ticket fields, guest types, rates, ticket guest type
    useEffect(() => {
        fetchArrivalTicketFields();
    }, []);

    // Fetches data for few fields only if the respective fields are configured
    useEffect(() => {
        if (!Array.isArray(arrivalTicketFields)) return;

        if (isTicketFieldConfigured(FIELD_ID.GuestType)) {
            fetchGuestTypes();
        }

        if (isTicketFieldConfigured(FIELD_ID.Rate)) {
            fetchRates();
        }

        if (isTicketFieldConfigured(FIELD_ID.Color)) {
            fetchColors();
        }

        if (isTicketFieldConfigured(FIELD_ID.Make)) {
            fetchMakes();
        }

        if (isTicketFieldConfigured(FIELD_ID.LicenseCountry)) {
            fetchCountries();
        }

        fetchTicketGuestType();
        fetchArrivalDetails();

    }, [arrivalTicketFields]);

    const fetchArrivalTicketFields = async () => {
        try {
            const result = await ticketFieldService.getEntityTicketFields({
                requestType: TICKET_FIELD_REQUEST_TYPE.ARRIVAL,
                entityID,
            });
            setArrivalTicketFields(modifyArrivalTicketFields(result.data));
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Arrival Ticket Fields", "error");
        }
    };

    //Fetches the arrival details
    const fetchArrivalDetails = async () => {
        try {
            let result = await valetParkingService.getArrivalDetails(entityID, ticketNumber);
            if (result != null) {
                setArrivalDetails(result.data);
                setFormActivityType(FORM_TYPE.UPDATE);
            }
        } catch (error) {
            if (error.response?.status === 400) {
                triggerEnqueueSnackBar("Failed to get Arrival Details", "error");
            }
        }
    };

    //Modified arrival ticket fields 
    //like inserting license country when license state is configured
    const modifyArrivalTicketFields = (ticketFields) => {
        if (!ticketFields) return ticketFields;
        try {
            const index = ticketFields.findIndex(field => field.fieldID === FIELD_ID.LicenseState);

            if (index !== -1) {
                let stateField = ticketFields[index];
                let countryField = { ...stateField, fieldName: "License Country", fieldID: FIELD_ID.LicenseCountry };
                ticketFields.splice(index, 0, countryField);
            }
        } catch (error) {
            triggerEnqueueSnackBar("Failed to modify ticket fields", "error");
        }
        return ticketFields;
    };

    const fetchGuestTypes = async () => {
        try {
            let result = await guestTypeService.getGuestTypes(entityID);
            setGuestTypes(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Guest Types", "error");
        }
    };

    const fetchRates = async () => {
        try {
            let entityIDForRates = parentEntity.typeid === ENTITY_TYPE.FacilityGroup ? parentEntity.entityid : entityID;
            let result = await rateService.getAvailableRates(entityIDForRates);
            setRates(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Rates", "error");
        }
    };

    const fetchColors = async () => {
        try {
            let result = await vehicleService.getVehicleColors();
            setColors(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Vehicle Colors", "error");
        }
    };

    const fetchMakes = async () => {
        try {
            let result = await vehicleService.getVehicleMakes();
            setMakes(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Vehicle Makes", "error");
        }
    };

    const fetchModels = async (makeID) => {
        try {
            let result = await vehicleService.getVehicleModels(makeID);
            setModels(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Vehicle Models", "error");
        }
    };

    const fetchCountries = async () => {
        try {
            let result = await countryService.getCountries();
            setCountries(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Countries", "error");
        }
    };

    const fetchStates = async (countryID) => {
        try {
            let result = await countryService.getSubdivisions(countryID);
            setStates(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get License States", "error");
        }
    };

    //Fetches the guest type under which the ticket number falls
    const fetchTicketGuestType = async () => {
        try {
            let result = await valetParkingService.getDetailsByTicketNumber(entityID, ticketNumber);
            setTicketGuestType(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Ticket Details", "error");
        }
    };

    const isTicketFieldConfigured = (fieldID) => {
        return arrivalTicketFields?.some(field => field.fieldID === fieldID);
    };

    return {
        arrivalTicketFields,
        guestTypes,
        rates,
        colors,
        makes,
        models,
        countries,
        states,
        parkedByEmployees,
        fetchStates,
        fetchModels,
        ticketGuestType,
        arrivalDetails,
        formActivityType
    };
};