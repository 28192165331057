import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  codeReader: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  toggleButtonRoot: {
    margin: theme.spacing(1),
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  toggleButtonSelected: {
    backgroundColor: `${theme.palette.secondary.dark} !important`,
  },
}));

export default useStyles;
