import { useState, useEffect } from 'react';
import ValetParkingService from '../../../services/ValetParkingService';
import TicketFieldService from '../../../services/TicketFieldService';
import GuestTypeService from '../../../services/GuestTypeService';
import RateService from '../../../services/RateService';
import apiClient from '../../../auth/apiClient';
import { FIELD_ID } from './RequestFieldConstants';
import { ENTITY_TYPE, TICKET_FIELD_REQUEST_TYPE } from "../../../constants";

//This hook is used to perform backend activities like fetching data needed to be binded to the form.
export const useValetRequest = (entityID, ticketNumber, parentEntity, triggerEnqueueSnackBar) => {
    const [requestTicketFields, setRequestTicketFields] = useState();
    const [guestTypes, setGuestTypes] = useState([]);
    const [rates, setRates] = useState([]);
    const [arrivalDetails, setArrivalDetails] = useState();

    const valetParkingService = new ValetParkingService(apiClient);
    const ticketFieldService = new TicketFieldService(apiClient);
    const guestTypeService = new GuestTypeService(apiClient);
    const rateService = new RateService(apiClient);

    //Fetches the initial details like request ticket fields, guest types, rates, arrival details
    useEffect(() => {
        fetchRequestTicketFields();
    }, []);

    // Fetches data for few fields only if the respective fields are configured
    useEffect(() => {
        if (!Array.isArray(requestTicketFields)) return;

        if (isTicketFieldConfigured(FIELD_ID.GuestType)) {
            fetchGuestTypes();
        }

        if (isTicketFieldConfigured(FIELD_ID.Rate)) {
            fetchRates();
        }

        fetchArrivalDetails();

    }, [requestTicketFields]);

    const fetchRequestTicketFields = async () => {
        try {
            const result = await ticketFieldService.getEntityTicketFields({
                requestType: TICKET_FIELD_REQUEST_TYPE.REQUEST,
                entityID,
            });
            setRequestTicketFields(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Arrival Ticket Fields", "error");
        }
    };
    
    const fetchGuestTypes = async () => {
        try {
            let result = await guestTypeService.getGuestTypes(entityID);
            setGuestTypes(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Guest Types", "error");
        }
    };

    const fetchRates = async () => {
        try {
            let entityIDForRates = parentEntity.typeid === ENTITY_TYPE.FacilityGroup ? parentEntity.entityid : entityID;
            let result = await rateService.getAvailableRates(entityIDForRates);
            setRates(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Rates", "error");
        }
    };

    //Fetches the arrival details
    const fetchArrivalDetails = async () => {
        try {
            let result = await valetParkingService.getArrivalDetails(entityID, ticketNumber);
            setArrivalDetails(result.data);
        } catch (error) {
            triggerEnqueueSnackBar("Failed to get Arrival Details", "error");
        }
    };
    
    const isTicketFieldConfigured = (fieldID) => {
        return requestTicketFields?.some(field => field.fieldID === fieldID);
    };

    return {
        requestTicketFields,
        guestTypes,
        rates,
        arrivalDetails        
    };
};