import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import clsx from "clsx";
import { Button, Typography, TextField, MenuItem } from "@material-ui/core";

import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useValetRequest } from "./useValetRequest";

import { FIELD_TYPE, FIELD_ID, FORM_FIELD_DETAILS } from "./RequestFieldConstants";

import { FindEntity } from "../../../state/slices/entities";
import { clearValetActivity } from "../../../state/slices/shiftSession/shiftSession";

const CashieredDevicePerformValetRequestActivity = ({ classes, onCancel }) => {
    const dispatch = useDispatch();
    const enqueueSnackbar = useEnqueueSnackbar();
    const [isGuestTypeChangedByReset, setGuestTypeChangedByReset] = useState(false);

    const entityID = useSelector((state) => state.entities?.ContextID);
    const parentEntity = useSelector((state) => {
        const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
        return FindEntity(state.entities?.EntityList ?? [], entity.parententityid);
    }, shallowEqual);

    const ticketNumber = useSelector((state) => state.shiftSession?.valetActivity?.ticketNumber);
    const notificationStyle = useSelector((state) => {
        return {
            small: state.shiftSession.smallScreen,
            toastLocation: state.shiftSession.toastLocation,
        };
    });

    const updateStateValue = (fieldID, value) => {
        let fiteredField = FORM_FIELD_DETAILS.find(field => field.fieldID === fieldID);
        if (!fiteredField) return;
        setValue(fiteredField.fieldStateName, value);
    };

    const triggerEnqueueSnackBar = (msg, variant, tag) => {
        enqueueSnackbar(msg, {
            variant: variant,
            tag: tag,
            anchorOrigin: notificationStyle.toastLocation,
            fullwidth: notificationStyle.small,
        });
    };

    const {
        requestTicketFields,
        guestTypes,
        rates,
        arrivalDetails
    } = useValetRequest(entityID, ticketNumber, parentEntity, triggerEnqueueSnackBar);

    const [requestData] = useState({
        firstName: "",
        lastName: "",
        parkedSpace: "",
        guestTypeID: "",
        rateID: ""
    });

    //This collection contains dropdown fields .
    // Each associated with respective data source,value column names,text column names
    const DROPDOWN_SOURCES = [
        { fieldID: FIELD_ID.GuestType, Source: guestTypes, TextColumn: "name", ValueColumn: "guestTypeID" },
        { fieldID: FIELD_ID.Rate, Source: rates, TextColumn: "name", ValueColumn: "rateBlobID" },
    ];

    const conditionalRequired = (isRequired, schema) => (isRequired ? schema.required("Required") : schema);
    const transformEmptyStringToNull = (value, originalValue) => (originalValue === "" ? null : value);

    const isFieldRequired = (fieldID) => {
        let field = requestTicketFields?.find(field => field.fieldID === fieldID);
        return field ? field.required : false;
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().trim().when("$isFirstNameRequired", conditionalRequired),
        lastName: Yup.string().trim().when("$isLastNameRequired", conditionalRequired),
        parkedSpace: Yup.string().trim().when("$isParkedSpaceRequired", conditionalRequired),
        guestTypeID: Yup.number().transform(transformEmptyStringToNull).nullable().typeError("Required").when("$isGuestTypeRequired", conditionalRequired),
        rateID: Yup.string().when("$isRateRequired", conditionalRequired),
    });

    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: { ...requestData },
        resolver: yupResolver(validationSchema),
        mode: "all",
        context: {
            isFirstNameRequired: isFieldRequired(FIELD_ID.FirstName),
            isLastNameRequired: isFieldRequired(FIELD_ID.LastName),
            isParkedSpaceRequired: isFieldRequired(FIELD_ID.ParkedSpace),
            isGuestTypeRequired: isFieldRequired(FIELD_ID.GuestType),
            isRateRequired: isFieldRequired(FIELD_ID.Rate),
        }
    });

    const selectedGuestType = useWatch({ control, name: 'guestTypeID' });

    //When GuestType is changed then Rate would be changed with the newly selected guest type's rate
    //This logic should not execute when guesttype changed due to form reset i.e., while binding arrival details.
    //    Reason being arrival might have different rate selected (which might not be related to guesttype rate)
    useEffect(() => {
        if (isGuestTypeChangedByReset) {
            //Reset the value to false to process any further selectedGuestType change  
            //which happens only due to user actions
            setGuestTypeChangedByReset(false);
            return;
        }

        if (selectedGuestType && selectedGuestType > 0) {

            const filteredGuestType = guestTypes.find(({ guestTypeID }) => guestTypeID === selectedGuestType);
            if (!filteredGuestType) return;

            updateStateValue(FIELD_ID.Rate, filteredGuestType.rateID);
        }
    }, [selectedGuestType, guestTypes]);

    //Populates/Resets the form with the arrival details
    useEffect(() => {
        if (!arrivalDetails || !arrivalDetails.arrival) return;

        const arrivalInfo = arrivalDetails.arrival;
        reset({
            firstName: arrivalInfo.firstName,
            lastName: arrivalInfo.lastName,
            guestTypeID: arrivalInfo.guestTypeID,
            rateID: arrivalInfo.rateID,
            parkedSpace: arrivalInfo.parkedSpace
        });
        setGuestTypeChangedByReset(true);
    }, [arrivalDetails]);

    const handleCancel = async () => {
        dispatch(clearValetActivity());
        onCancel();
    };

    const onSubmit = async (data) => {
        //Below console.log can be removed once request data save processing is implented in future.
        //Logging this for now just to have a proof that form data is getting saved correctly.
        console.log(data);
    };

    const renderTextBoxField = (ticketField, formField) => (
        <Controller
            key={formField.fieldID}
            name={formField.fieldStateName}
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    id={formField.fieldStateName}
                    className={clsx(formField.fieldStateName)}
                    label={`${ticketField.fieldName}${ticketField.required ? " *" : ""}`}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={!!errors[formField.fieldStateName]}
                    helperText={errors[formField.fieldStateName]?.message}
                    FormHelperTextProps={{
                        "data-testid": `${formField.fieldStateName}-helpertext`
                    }}
                />
            )}
        />
    );

    const renderMenuItemsByFieldID = (ticketField) => {
        if (!ticketField) return null;

        const dropDownSource = DROPDOWN_SOURCES.find(item => item.fieldID === ticketField.fieldID);
        const items = dropDownSource?.Source || [];

        if (items.length > 0) {
            return items.map(item => (
                <MenuItem key={item[dropDownSource.ValueColumn]} value={item[dropDownSource.ValueColumn]}>
                    {item[dropDownSource.TextColumn]}
                </MenuItem>
            ));
        }

        return [];
    };

    const renderDropdownField = (ticketField, formField) => {
        const menuItems = renderMenuItemsByFieldID(ticketField);
        const hasMenuItems = menuItems && menuItems.length > 0;

        return (
            <Controller
                key={formField.fieldID}
                name={formField.fieldStateName}
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        id={formField.fieldStateName}
                        label={`${ticketField.fieldName}${ticketField.required ? " *" : ""}`}
                        fullWidth
                        error={!!errors[formField.fieldStateName]}
                        helperText={errors[formField.fieldStateName]?.message}
                        className={clsx(formField.fieldStateName)}
                        select={hasMenuItems} // Conditionally apply the select prop to avoid warnings when there are no options
                        variant="outlined"
                        margin="normal"
                        SelectProps={{
                            SelectDisplayProps: { "data-testid": `${formField.fieldStateName}-select` },
                        }}
                        FormHelperTextProps={{
                            "data-testid": `${formField.fieldStateName}-helpertext`
                        }}
                    >
                        {menuItems}
                    </TextField>
                )}
            />
        );
    };

    const renderTicketFields = () => (
        requestTicketFields?.map(ticketField => {
            const formField = FORM_FIELD_DETAILS.find(field => field.fieldID === ticketField.fieldID);
            if (!formField) return null;
            switch (formField.fieldType) {
                case FIELD_TYPE.TextBox:
                    return renderTextBoxField(ticketField, formField);
                case FIELD_TYPE.DropDown:
                    return renderDropdownField(ticketField, formField);
                default:
                    return null;
            }
        })
    );

    return (
        <div className={classes.step} data-testid="valet-request-step">
            <div className={classes.valetFormContent}>
                <Typography
                    variant="h4"
                    component="h1"
                    className={clsx("request-header", classes.header)}
                >
                    Request Ticket
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    id="ticketNumber"
                    label="Ticket Number"
                    defaultValue={ticketNumber}
                    disabled
                    fullWidth
                />
                {renderTicketFields()}
                <div>
                    <Button
                        className={clsx("cancelBtn", classes.buttonRight)}
                        name="cancel"
                        variant="contained"
                        onClick={handleCancel}
                        disabled={isSubmitting}
                        data-testid="cancelBtn"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        name="submit"
                        type="submit"
                        variant="contained"
                        className={clsx("paymentBtn", classes.buttonRight)}
                        onClick={handleSubmit(onSubmit)}
                        disabled={isSubmitting}
                        data-testid="paymentBtn"
                    >
                        Payment
                    </Button>
                </div>
            </div>
        </div>
    );
};

CashieredDevicePerformValetRequestActivity.defaultProps = {
    classes: {},
    onCancel: () => { },
};

CashieredDevicePerformValetRequestActivity.propTypes = {
    classes: PropTypes.object,
    onCancel: PropTypes.func,
};

export default CashieredDevicePerformValetRequestActivity;
