import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import {
  Typography,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableContainer
} from "@material-ui/core";

const CardFormatList = ({ cardFormatArray, handleDeleteButton }) => {
  const classes = useStyles();
  const [cardFormats, setCardFormats] = useState([]);

  useEffect(() => {
    setCardFormats([...cardFormatArray]);
  }, [cardFormatArray]);

  return (
    <>
      <Grid container className={clsx("table-container", classes.tableContainer)}>
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer>
          <Table className={clsx("table", classes.table)}>

            {cardFormats == undefined || cardFormats == null || cardFormats?.length <= 0 ? (
              <Typography className={clsx("no-data-message", classes.centerContentColumn, classes.noDataMessage)}>
                There is no data to display.
              </Typography>
            ) : (
              <TableBody className={ clsx("table-body")}>
                {cardFormats.map((row) => {
                  return (
                    <TableRow key={row.id} className={clsx([`${row.formatName?.replace(/\s+/g, "-")}`,"table-row"], classes.tableRow)}>
                      <TableCell
                        className={clsx([`${row.formatName?.replace(/\s+/g, "-")}`,"buttons-table-cell"], classes.buttonsTableCell)}
                      >
                        <Typography className={clsx("table-cell-text")}>{row.formatName}</Typography>
                        
                      </TableCell>
                      <TableCell align="right">
                      <Button
                          variant="contained"
                          color="secondary"
                          className={clsx([`${row.formatName?.replace(/\s+/g, "-")}`,"remove-button"], classes.tableButtons)}
                          onClick={() => handleDeleteButton(row.cardFormatID)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default CardFormatList;
