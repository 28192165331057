import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import TicketService from "../../../services/TicketService";
import PropTypes from "prop-types";
import { Grid, ListItem, Card, CardContent, List } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import apiClient from "../../../auth/apiClient";
import clsx from "clsx";
import SearchBar from "../../SearchBar";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import useCancellationToken from "../../../hooks/useCancellationToken";

const ticketService = new TicketService(apiClient);

const TicketSearch = ({ facilityID, onTicketClick }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const enqueueSnackbar = useEnqueueSnackbar();
  const [formattedTickets, setFormattedTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    execute: executeTicketSearchQuery,
    cancel: cancelTicketSearchQuery,
    inProgress,
  } = useCancellationToken({
    func: getTickets,
    errHandleFunc: () =>
      enqueueSnackbar("Failed to retrieve tickets", {
        variant: "error",
        tag: "FailedToRetrieveTickets",
      }),
  });

  useEffect(() => {
    setLoading(inProgress);
  }, [inProgress]);

  async function getTickets({ facilityID, searchTerm, cancelToken }) {
    let response = await ticketService.searchForTicketsSlim({
      facilityID,
      searchTerm,
      cancelToken,
    });

    switch (response?.status ?? null) {
      case 200: {
        const allTickets = response?.data?.collection ?? [];
        setFormattedTickets(formatTickets(allTickets));
        break;
      }
      case 400: {
        if (typeof response.data === "string") {
          enqueueSnackbar(response.data, { variant: "warning" });
        }
        enqueueSnackbar("Failed to retrieve tickets", {
          variant: "error",
          tag: "FailedToRetrieveTickets",
        });
        break;
      }
      case null: {
        enqueueSnackbar("Failed to retrieve tickets", {
          variant: "error",
          tag: "FailedToRetrieveTickets",
        });
        break;
      }
      default: {
        enqueueSnackbar(
          `Failed to retrieve tickets status: ${response.status}`,
          { variant: "error", tag: "FailedToRetrieveTickets" }
        );
        break;
      }
    }
  }

  const formatTickets = (tickets) => {
    return tickets?.map((ticket, index) => {
      return (
        ticket !== undefined &&
        ticket.ticketID !== null &&
        ticket.ticketID !== undefined && (
          <ListItem
            key={index}
            className={clsx("ticket-row-item", classes.listItem)}
          >
            <Card
              variant="outlined"
              data-id={`ticket-${index}`}
              data-value={ticket.ticketID}
              className={clsx(classes.card, "ticket-card")}
              onClick={() => onTicketClick(ticket.ticketID)}
            >
              <CardContent
                className={clsx("ticket-card-content", classes.cardContent)}
              >
                {ticket.ticketID}
              </CardContent>
            </Card>
          </ListItem>
        )
      );
    });
  };

  const handleSearchChange = (search) => {
    setSearchTerm(search);
    if (!search || _.isEmpty(search) || search.length < 3) {
      cancelTicketSearchQuery();
      setFormattedTickets([]);
      return;
    }
    executeTicketSearchQuery({ searchTerm: search, facilityID });
  };

  return (
    <Grid
      container
      spacing={1}
      className={clsx("ticket-table", classes.ticketTable)}
    >
      <SearchBar
        className={clsx("pageable-search-bar")}
        placeholder={"Search..."}
        label={"Tickets"}
        delay={250}
        onChange={handleSearchChange}
        value={searchTerm}
        data-id="ticket-searchbar"
        isLoading={loading}
      />
      {!searchTerm ? (
        <></>
      ) : formattedTickets?.length > 0 ? (
        <List className={classes.list} data-options={formattedTickets}>
          {formattedTickets}
        </List>
      ) : (
        <Typography className={clsx("no-ticket-message")}>
          No Ticket Found
        </Typography>
      )}
    </Grid>
  );
};

TicketSearch.defaultProps = {
  onTicketClick: () => {},
};

TicketSearch.propTypes = {
  searchTermInput: PropTypes.string,
  facilityID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onTicketClick: PropTypes.func,
};
export default TicketSearch;
