import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  codeReader: {
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  toggleButtonRoot: {
    margin: theme.spacing(1),
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  toggleButtonSelected: {
    backgroundColor: `${theme.palette.secondary.dark} !important`,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 3.75,
  },
  searchBar: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    height: "fit-content",
  },
  smallSearchBar: {
    width: "100%",
  },
}));

export default useStyles;
