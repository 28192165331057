
export const FIELD_TYPE = {
    TextBox: 1,
    DropDown: 2,
};

//Field_ID refers to arrival fields FieldID in DB table:valetareaticketfields
export const FIELD_ID = {
    FirstName: 1,
    LastName: 2,
    Make: 3,
    Model: 4,
    Color: 5,
    LicensePlate: 6,
    //There is no LicenseCountry field in db table. But included here to inject this field 
    //dynamically when LicenseState is configured
    LicenseCountry: 7.1,
    LicenseState: 7,
    VIN: 8,
    Email: 9,
    PhoneNumber: 10,
    GuestType: 11,
    Rate: 12,
    ParkedByEmployee: 13,
    ParkedSpace: 14,
};

//This collection contains different arrival fields.
//Each associated with field type(i.e., TextBox,Dropdown), respective statename (used during field rendering,yup validation)
export const FORM_FIELD_DETAILS = [
    { fieldID: FIELD_ID.FirstName, fieldStateName: "firstName", fieldType: FIELD_TYPE.TextBox },
    { fieldID: FIELD_ID.LastName, fieldStateName: "lastName", fieldType: FIELD_TYPE.TextBox },
    { fieldID: FIELD_ID.Make, fieldStateName: "makeID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.Model, fieldStateName: "modelID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.Color, fieldStateName: "colorID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.LicensePlate, fieldStateName: "licensePlate", fieldType: FIELD_TYPE.TextBox },
    { fieldID: FIELD_ID.LicenseCountry, fieldStateName: "licenseCountryID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.LicenseState, fieldStateName: "licenseStateID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.VIN, fieldStateName: "vin", fieldType: FIELD_TYPE.TextBox },
    { fieldID: FIELD_ID.Email, fieldStateName: "email", fieldType: FIELD_TYPE.TextBox },
    { fieldID: FIELD_ID.PhoneNumber, fieldStateName: "phoneNumber", fieldType: FIELD_TYPE.TextBox },
    { fieldID: FIELD_ID.GuestType, fieldStateName: "guestTypeID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.Rate, fieldStateName: "rateID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.ParkedByEmployee, fieldStateName: "parkedBy", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.ParkedSpace, fieldStateName: "parkedSpace", fieldType: FIELD_TYPE.TextBox },
];
export const FORM_TYPE = {
    CREATE: 1,
    UPDATE: 2,
};
