/* Services */
import ValidationAccountService from "../../../services/ValidationAccountService";

/* Utilites */
import _ from "lodash";

export default class ValidationOfferRetriever {
  constructor(apiClient) {
    this.validationAccountService = new ValidationAccountService(apiClient);
  }

  getValidationOffers = async (facilityID, userID, selectedOffer, scopeAwareFacilityID) => {
    let validationAccounts;
    let response;
    try {
      response = await this.validationAccountService.getValidationAccountsForUser(
        scopeAwareFacilityID ?? facilityID,
        userID,
        true
      );

      validationAccounts = response.data?.map((x) => x.contractHolderID);
    } catch (err) {
      return {
        success: false,
        reason: "FailedToRetrieveValidationAccountInfo",
        reasonPhrase: "Failed to retrieve validation account info",
      };
    }

    if (!validationAccounts || _.isEmpty(validationAccounts))
      return { success: true, offers: [] };

    try {
      response = await this.validationAccountService.getValidationOffersForCashier(
        facilityID,
        validationAccounts,
        facilityID
      );
      let tmpOffers = response.data ?? [];

      if (selectedOffer && tmpOffers.length > 0) {
        // POE offers only allow Percentage, Amount, and Flat Fee
        tmpOffers.forEach((offer) => {
          offer.offers = offer.offers?.filter(
            (x) =>
              x.type == "Percentage" ||
              x.type == "Amount" ||
              x.type == "Flat Fee"
          );
        });
      }

      return {
        success: true,
        offers: tmpOffers,
      };
    } catch (err) {
      console.error("Retrieve validation offers error", err);
      return {
        success: false,
        reasonPhrase: "Failed to retrieve validation offers",
        reason: "FailedToRetrieveValidationOffers",
      };
    }
  };
}
