import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Grid,
  List,
  Card,
  Button,
  ListItem,
  Typography,
  CardContent,
} from "@material-ui/core";
import Moment from "react-moment";
import { useStyles } from "./styles";
import SearchBar from "../SearchBar";
import apiClient from "../../auth/apiClient";
import useCurrentUser from "../../hooks/useCurrentUser";
import useHasPermissions from "../../hooks/useHasPermissions";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import useCurrentFacilityTimezone from "../../hooks/useCurrentFacilityTimezone";
import CashierShiftsService from "../../services/CashierShiftsService";
import { useDispatch } from "react-redux";
import { endCashierShift } from "../../state/slices/shiftSession/shiftSession";
import useCashierShiftState from "../../state/slices/shiftSession/useCashierShiftState";
import CashierService from "../../services/CashierService";



const cashierShiftsService = new CashierShiftsService(apiClient);
const cashierService = new CashierService(apiClient);

const CashierSessions = () => {
  const classes = useStyles();
  const [shifts, setShifts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();
  const { showDialog } = useConfirmationDialog();
  const { hasPermissions } = useHasPermissions();
  const { currentShiftID } = useCashierShiftState();
  const { convertUtcDate } = useCurrentFacilityTimezone();

  const viewSessionsPermission = hasPermissions(["cashiersessions.view"]);
  const editSessionsPermission = hasPermissions(["cashiersessions.edit"]);

  const populateShifts = async () => {
    try {
      const result = await cashierShiftsService.getCashierShifts(facilityID);
      if (result.data != null && result.data.length > 0) setShifts(result.data);
    } catch (e) {
      enqueueSnackbar("Failed to get cashier shifts for facility", {
        variant: "error",
        tag: "cashierShiftErr",
      });
    }
  };

  const handleEndShift = async (shiftID, username) => {
    try {
      const response = await showDialog({
        title: `End Shift`,
        message: `This will end the selected shift for ${username}. Are you sure?`,
        buttons: [
          { name: "Yes", color: "primary" },
          { name: "No", color: "secondary" },
        ],
      });
      if (response === "No") return;
      const res = await cashierShiftsService.endShift(
        shiftID,
        currentUser.UserID
      );
      if (res.status === 204) {
        setShifts([...shifts].filter((x) => x.shiftID !== shiftID));
        if (currentShiftID === shiftID) dispatch(endCashierShift());
      } else {
        enqueueSnackbar("Failed to end cashier shift", {
          variant: "error",
          tag: "cashierShiftErr",
        });
      }
    } catch (e) {
      enqueueSnackbar("Failed to end cashier shift", {
        variant: "error",
        tag: "cashierShiftErr",
      });
    }
  };

  const handleEndShiftWhenTransactionInProgress = async (userID) => {
    await cashierService.clearActiveTransaction(userID);
  }
  const handleSearchChange = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const filterShifts = () => {
    return shifts?.filter(
      (x) =>
        `${x.firstName} ${x.lastName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        x.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  useEffect(() => {
    populateShifts();
  }, []);

  return (
    <>
      {viewSessionsPermission && (
        <Grid container>
          <Grid
            item
            xs={8}
            md={8}
            lg={8}
            className={clsx(classes.searchContainer)}
          >
            <SearchBar
              className={clsx("cashier-search-bar", classes.searchBar)}
              delay={100}
              onChange={handleSearchChange}
              defaultSearchTerm={""}
              label="Users"
              placeholder={"Search by name or email address"}
            />
          </Grid>
          <Grid item xs={12}>
            <List className={clsx("shift-list")} data-testid="shiftList">
              {filterShifts()?.length === 0 && (
                <Typography
                  variant="subtitle1"
                  className={clsx(classes.cashierSessionsText)}
                  data-testid="no-cashier-sessions-text"
                  data-text="No cashier sessions found"
                >
                  No cashier sessions found
                </Typography>
              )}
              {filterShifts()?.map((shift, index) => {
                return (
                  <ListItem className={clsx("shift-row")} key={index} data-testid={`shiftRow`}>
                    <Card
                      variant="outlined"
                      data-id={shift.firstName}
                      className={clsx(classes.card)}
                    >
                      <CardContent className={clsx(classes.cardContent)}>
                        <Grid container spacing={2}>
                          <Grid container item xs={12} sm={8} lg={4}>
                            <Grid className={clsx("cashier-name")} item xs={12}>
                              <b>{`${shift.firstName} ${shift.lastName}`}</b>
                            </Grid>
                            <Grid className={clsx("cashier-email")} item xs={12}>
                              {shift.email}
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} sm={4} lg={3}>
                            <Grid className={clsx("signin-time")} item xs={12}>
                              Signed in on{" "}
                              <Moment format={"MM/DD/YYYY hh:mm a"}>
                                {convertUtcDate(shift.shiftStart)}
                              </Moment>
                            </Grid>
                            <Grid className={clsx("device-name")} item xs={12}>
                              {shift.deviceName ?? "Roaming Cashier"}
                            </Grid>
                          </Grid>
                          <Grid className={clsx("shift-duration")} item xs={12} lg={3}>
                            Duration:{" "}
                            <Moment
                              format={"DD [days], HH [hours], mm [minutes]"}
                              interval={1000}
                              durationFromNow
                            >
                              {convertUtcDate(shift.shiftStart)}
                            </Moment>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            lg={2}
                            justify="flex-end"
                          >
                            {editSessionsPermission && (
                              <Button
                                className={clsx("end-shift")}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  handleEndShiftWhenTransactionInProgress(shift.userID);
                                  handleEndShift(
                                    shift.shiftID,
                                    `${shift.firstName} ${shift.lastName}`
                                  );
                                }}
                                data-testid={`endShiftBtn-${shift.shiftID}`}
                              >
                                End Shift
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default CashierSessions;
