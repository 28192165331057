import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import { Typography, Button, CircularProgress } from "@material-ui/core";
import { NavConfirmation } from "../../UserConfirmation";

/* Style */
import { useStyles } from "./styles";
import clsx from "clsx";

/* Utilities */
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useHistory } from "react-router-dom";
import usePos, { ResponseTypes } from "../Utilities/usePos";
import _ from "lodash";

/* State */
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useSelector, useDispatch } from "react-redux";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { addPayment, hydrateTransaction as hydrateTransactionThunk } from "../../../state/slices/shiftSession/shiftSession";
import { PAYMENT_TYPES, EMPTY_GUID } from "../../../constants";

const PayCredit = ({
  classes,
  onComplete,
  onFailedTransaction,
  onInterrupt,
}) => {
  const style = useStyles();
  const [loading, setLoading] = useState({
    loadingMessage: "Initializing Transaction",
    isLoading: true,
  });
  const currentUser = useCurrentUser();
  const [canCancel, setCanCancel] = useState(false);
  const [canShowPrompt, setCanShowPrompt] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();
  const history = useHistory();
  const notificationStyle = useSelector((state) => {
    return {
      small: state.shiftSession.smallScreen,
      toastLocation: state.shiftSession.toastLocation,
    };
  });
  const { facilityID } = useCurrentFacility();
  const shiftInfo = useSelector((state) => state.shiftSession);
  const hydratedPaymentStatus = useSelector(
    (state) => state.shiftSession.transaction.status
  );
  const dispatch = useDispatch();

  const { totalCost } = shiftInfo.transaction;

  const { processCreditPayment, cancelCredit, displayLines } = usePos({
    facilityID,
    cashierID: currentUser.UserID,
    onTransactionChanged: handleTransactionUpdate,
  });

  useEffect(() => {
    if (!hydratedPaymentStatus) return;
    if (
      hydratedPaymentStatus === ResponseTypes.AwaitingPaymentCompleted ||
      hydratedPaymentStatus === ResponseTypes.InProgress
    ) {
        setCanCancel(true);
        setCanShowPrompt(true);
    }
  }, [hydratedPaymentStatus]);

  useEffect(() => {
    initiateCreditPayment();
  }, []);

  async function handleTransactionUpdate(transaction) {
    try {
      setLoading({
        loadingMessage: "Please wait...",
        isLoading: true,
      });
      setCanCancel(false);

      if (transaction.Status === ResponseTypes.Failed) {
        setCanShowPrompt(false);

        enqueueSnackbar("Failed to process credit card please retry", {
          variant: "error",
          anchorOrigin: notificationStyle.toastLocation,
          fullwidth: notificationStyle.small,
        });

        // allowing the prompt flag to toggle
        setTimeout(() => {
          onFailedTransaction();
        }, 100);
      }

      if (transaction.Status === ResponseTypes.Complete) {
        setCanShowPrompt(false);
        dispatch(addPayment({ total: totalCost, paymentType: PAYMENT_TYPES.CreditCard }));
        // allowing the prompt flag to toggle
        setTimeout(() => {
          onComplete();
        }, 100);
      } else if (
        transaction.Status === ResponseTypes.Cancelled ||
        transaction.Status === ResponseTypes.Declined
      ) {
        setCanShowPrompt(false);

        const concatenatedDisplayLines = !transaction.DisplayLines[1]
          ? transaction.DisplayLines[0]
          : `${transaction.DisplayLines[0]} ${transaction.DisplayLines[1]}`;

        if (!_.isEmpty(concatenatedDisplayLines))
          enqueueSnackbar(concatenatedDisplayLines, {
            variant: "error",
            anchorOrigin: notificationStyle.toastLocation,
            fullwidth: notificationStyle.small,
          });

        // allowing the prompt flag to toggle
        setTimeout(() => {
          onInterrupt();
        }, 100);
      }
    } finally {
      setLoading({
        loadingMessage: "",
        isLoading: false,
      });
      setCanCancel(true);
    }
  }

  const initiateCreditPayment = async () => {
    try {
      await processCreditPayment(totalCost);
      setCanShowPrompt(true);
      setLoading({
        loadingMessage: "",
        isLoading: false,
      });
      setCanCancel(true);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
      if (hydratedPaymentStatus !== ResponseTypes.InProgress
        && hydratedPaymentStatus !== ResponseTypes.AwaitingPaymentCompleted ) {
          history.goBack();
      } else {
        setCanShowPrompt(true);
        setLoading({
          loadingMessage: "",
          isLoading: false,
        });
        setCanCancel(true);
      }
    }
  };

  const handleTransactionCancel = async () => {
    try {
      setCanShowPrompt(false);
      const result = await cancelCredit();
      if (result.success === false && result.transactionReference === EMPTY_GUID)
        dispatch(hydrateTransactionThunk(currentUser.UserID));
      else
        history.push("/cashieredDevice/payment");
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
      setCanShowPrompt(true);
    }
  };

  return (
    <div className={classes.step} data-testid="v2-pay-credit">
      <NavConfirmation
        when={canShowPrompt}
        title="Warning!"
        message="Please complete your transaction before leaving the page."
        buttons={[
          {
            text: "Ok",
            attribute: "credit-navigation-ok-btn",
            state: false,
            color: "primary",
            order: 0,
          },
        ]}
      />
      {loading.isLoading && (
        <>
          <CircularProgress size="40px" />
          <Typography
            variant="h5"
            component="div"
            align="center"
            data-testid="circular-progress-loader"
          >
            {loading.loadingMessage}
          </Typography>
        </>
      )}
      {!loading.isLoading && (
        <>
          <div>
            <Typography
              variant="h5"
              component="p"
              align="center"
              data-testid="terminal-display-line-1"
            >
              {displayLines.displayLine1}
            </Typography>
            <Typography
              variant="h5"
              component="p"
              align="center"
              data-testid="terminal-display-line-2"
            >
              {displayLines.displayLine2}
            </Typography>
          </div>
          <Typography
            variant="h4"
            component="div"
            align="center"
            data-testid="total-cost"
            className={clsx(classes.totalCost, style.payCreditTotalCost)}
            role="caption"
          >
            {`$${totalCost?.toFixed(2)}`}
          </Typography>
          {canCancel && (
            <Button
              variant="contained"
              color="secondary"
              data-testid="transaction-cancel-button"
              className={classes.transactionCancelButton}
              onClick={handleTransactionCancel}
            >
              Cancel
            </Button>
          )}
        </>
      )}
    </div>
  );
};

PayCredit.defaultProps = {
  onComplete: () => {},
  onFailedTransaction: () => {},
  onInterrupt: () => {},
};

PayCredit.propTypes = {
  onComplete: PropTypes.func,
  onFailedTransaction: PropTypes.func,
  onInterrupt: PropTypes.func,
};

export default PayCredit;
