
export const FIELD_TYPE = {
    TextBox: 1,
    DropDown: 2,
};

//Field_ID refers to request fields FieldID in DB table:valetareaticketfields
export const FIELD_ID = {
    FirstName: 15,
    LastName: 16,
    GuestType: 17,
    Rate: 18,
    ParkedSpace: 22,
};

//This collection contains different request fields.
//Each associated with field type(i.e., TextBox,Dropdown), respective statename (used during field rendering,yup validation)
export const FORM_FIELD_DETAILS = [
    { fieldID: FIELD_ID.FirstName, fieldStateName: "firstName", fieldType: FIELD_TYPE.TextBox },
    { fieldID: FIELD_ID.LastName, fieldStateName: "lastName", fieldType: FIELD_TYPE.TextBox },
    { fieldID: FIELD_ID.GuestType, fieldStateName: "guestTypeID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.Rate, fieldStateName: "rateID", fieldType: FIELD_TYPE.DropDown },
    { fieldID: FIELD_ID.ParkedSpace, fieldStateName: "parkedSpace", fieldType: FIELD_TYPE.TextBox },
];

