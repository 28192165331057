import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    card: {
        width: "100%",
    },
    cardContent: {
        width: "100%",
    },
    searchContainer: {
        marginLeft: theme.spacing(2), 
        marginTop: theme.spacing(2)
    },
    cashierSessionsText: {
        marginLeft: theme.spacing(3)
    },
}));