import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: `2.5px 2.5px 8px ${theme.palette.grey.light}`,
      },
      text: {
        ...theme.typography.button,
      },
      container: {
        padding: theme.spacing(2),
      },
    cardHeader: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.header,
        color: theme.palette.text.secondary,
        ...theme.typography.button,
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0),
        },
      },
      headerTitle: {
        textAlign: "left",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            
        },
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
      },
      headerAddButton: {
        textAlign: "right",
        justifyContent: "right",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingBottom: theme.spacing(1)
        },
      },

}));