import React, { useState, useEffect } from "react";
import { Typography, Select, MenuItem, ListSubheader, FormControl, InputLabel, Grid, Button, Table, TableCell, TableRow, TableBody, TablePagination } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";

const CardFormatPicker = ({ 
  handleAddButton,
  existingCardFormats
 }) => {
  const [selectedFormat, setselectedFormat] = useState({});
  const [cardFormatList, setCardFormatList] = useState([]);
  const classes = useStyles();
  
  useEffect(() => {
    setCardFormatList([ ...existingCardFormats ]);
  }, [existingCardFormats]);

  const updateSelectedFormat = (format) => {
      let arr = [ ...cardFormatList ];
      let formatChosen = arr.find(x => x.formatName == format)
      
      if(selectedFormat == undefined || selectedFormat == null) {
        setselectedFormat({})
      } else {
        setselectedFormat({ ...formatChosen })
      }
  }

  return (
    <>
      <Grid container className={ clsx("card-format-container", classes.dropDownAddRow )} direction="row">
          <Grid xs={9} md={10} lg={10}>
            <FormControl className={ clsx("form-control", classes.formControl )} variant="outlined">
              <InputLabel id="card-format-label" className={clsx("dropdown-label")}>Select a card format</InputLabel>
              <Select
               value={selectedFormat?.description}
               variant="outlined" 
               labelId="card-format-label" 
               label="Select a card format" 
               onChange={e => updateSelectedFormat(e.target.value)} 
               className={ clsx("dropdown", classes.dropdown )}
               >
                {cardFormatList?.map((row) => {
                  return (
                    <MenuItem key={row.cardFormatID} value={row.formatName} className={clsx([`${row.formatName.replace(/\s+/g, "-")}`, 'menu-item'], classes.selectMenuItem)}>
                      {row.formatName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
            <Grid xs={2} md={2} lg={2} className={ classes.headerAddButton }>
              <Button
                className={clsx(`add-button`)}
                color="primary"
                variant="contained"
                onClick={() => handleAddButton(selectedFormat)}
              >
                Add
              </Button>
            </Grid>
        </Grid>
    </>
  );
};

export default CardFormatPicker;
