/* Utilities */
import moment from "moment";

/* Services */
import RateService from "../../../services/RateService";

export default class RateCalculators {
  constructor(apiClient) {
    this.apiClient = apiClient;
    this.rateService = new RateService(this.apiClient);
  }

  calculateRateForTicket = async (ticket, cashierID) => {
    try {
      let rateResponse;
      // if lag time expired then need to recalc balance; entryTime will be entry time in current time zone,
      //   ExitTime will be last scan time to current time zone
      let withinValidRange =
        ticket.rateValidUntil != null
          ? moment(ticket.rateValidUntil + "Z").isSameOrAfter(
              moment().toISOString()
            )
          : false;
      if (withinValidRange) return { baseFee: 0, totalToPay: 0 };

      rateResponse = await this.rateService.CalculateCashierRateForCredential({
        credentialID: ticket.credentialID,
        cashierID,
      });

      return rateResponse.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  calculatePOERate = async (cashierID, offer, timeZone, validations, adjustedFee) => {
    let response;
    try {
      response = await this.rateService.CalculateCashierRatePOE({
        cashierID,
        timeZone,
        offerID: offer.offerID,
        validations,
        adjustedFee
      });

      return {
        success: true,
        rate: response.data,
      };
    } catch (err) {
      return {
        success: false,
        reason: "UnableToCalculateRate",
        reasonPhrase: "Unable to Calculate Rate",
      };
    }
  };
}
