import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import CardSystemCodesService from "../../services/CardSystemCodesService";
import apiClient from "../../auth/apiClient";
import { filterInt } from "../../utils/filterInt";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import { useSelector } from "react-redux";

import {
  TextField,
  Typography,
  Grid,
  Divider,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import useHasPermissions from "./../../hooks/useHasPermissions";

const cardSystemCodesService = new CardSystemCodesService(apiClient);

const CardSystemCodes = ({ entityID, onClose }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [codes, setCodes] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const { hasPermissions } = useHasPermissions();
  const editSettingsPermission = hasPermissions(["overview.facilities.editsettings"]);
  const [newCodeInput, setNewCodeInput] = useState("");
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);
  const facilityID = facilityGroupId || entityID;

  const getCardCodes = async () => {
    try {
      var result = await cardSystemCodesService.getCardSystemCodes(facilityID);
      setCodes([...result.data.cardSystemCodes]);
    } catch (error) {
      enqueueSnackbar("Failed to get card system codes for facility", {
        variant: "error",
        tag: "cardSystemCodeErr"
      });
    }
  };

  const handleDeleteButton = async (code) => {
    try {
      await cardSystemCodesService.deleteCardSystemCode(facilityID, code);
      setCodes([...codes.filter((x) => x.code != code)]);
      enqueueSnackbar(`Removed card system code '${code}'`);
    } catch (err) {
      enqueueSnackbar("Failed to remove card system code", {
        variant: "error",
        tag: "cardSystemCodeErr"
      });
    }
  };

  const formatSystemCode = (value) => {
    if(parseInt(value) === 0 || isNaN(filterInt(value))) return value;
    else return parseInt(value);
  }


  const saveCardCode = async () => {
    try {

      if (codes.map(c=>c.code).includes(newCodeInput)) throw new Error("System code already exists.");
      var result = await cardSystemCodesService.createCardSystemCodes(facilityID, newCodeInput);
      setCodes((c) => [...c, result.data]);
    } catch (error) {
      enqueueSnackbar(error.message ? error.message : "Failed to save card system code", {
        variant: "error",
        tag: "cardSystemCodeErr"
      });
    }
  };

  const onSave = () => {
    // save new input
    setIsAdding(false);
    saveCardCode();
  };

  const onCancel = () => {
    // cancel the edit/add
    setIsAdding(false);
  };

  useEffect(() => {
    getCardCodes();
  }, [entityID, facilityGroupId]);

  return (
    <>
      <Grid container className={clsx(`title-container`, "system-codes-title", classes.container)}>
        <Grid item xs={12} mg={12} lg={12}>
          <Typography variant="h5" color="primary" component="h2" className={clsx("title")}>
            System Codes
          </Typography>
          <Divider style={{ marginBottom: 15 }}></Divider>
        </Grid>
      </Grid>
      <Grid container className={clsx("table-container", "system-codes-container", classes.tableContainer)}>
        <Grid item xs={12} mg={12} lg={12}>
          {editSettingsPermission ? (
            <TableContainer>
              <Table className={clsx("table", classes.table)}>
                {(codes?.length ?? 0) === 0 && !isAdding ? (
                  <>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            data-testid="no-data-message"
                            className={clsx(classes.centerContentColumn, classes.noDataMessage)}
                          >
                            No facility code set.
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          <Button
                            name="addCode"
                            data-testid="newCodeBtn"
                            onClick={() => {
                              setIsAdding(true);
                            }}
                            color="primary"
                            variant="contained"
                            className="new-code-btn"
                          >
                            New Code
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  <TableBody className="table-body">
                    {codes.map((code) => (
                      <TableRow key={code.code} data-testid="codeRow" className={clsx("table-row", classes.tableRow)} facilitycode={code.code} >
                        <TableCell>
                          <Typography className={clsx("table-cell-text")}>{code.code}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            data-testid="removeBtn"
                            variant="contained"
                            color="secondary"
                            className={clsx("remove-button", classes.tableButtons)}
                            onClick={() => handleDeleteButton(code.code)}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {isAdding ? (
                      <TableRow className={classes.tableRow}>
                        <TableCell>
                          <TextField
                            className="newCode"
                            inputProps={{
                              "data-testid": "newCode"
                            }}
                            id="newCode"
                            label="Code"
                            onChange={(e) => setNewCodeInput(formatSystemCode(e.target.value))}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Button color="primary" variant="contained" className="save-btn" data-testid="buttonSave" onClick={onSave}>
                            Save
                          </Button>
                          <Button color="primary" variant="contained" className="cancel-btn" onClick={onCancel}>
                            Cancel
                          </Button>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow className={classes.tableRow}>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          <Button
                            data-testid="newCodeBtn"
                            onClick={() => {
                              setIsAdding(true);
                            }}
                            color="primary"
                            variant="contained"
                            className="new-code-btn"
                          >
                            New Code
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          ) : (
            <Grid item xs={12} md={12} lg={12}>
              <Typography align="center" className={clsx("no-permissions-text")}>
                You do not have permission to view card system codes.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CardSystemCodes;
