import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    selectListHeader: {
        backgroundColor: theme.palette.header,
    },
    selectMenuItem: {
        paddingLeft: theme.spacing(5)
    },
    selectSubHeader: {
    },
    dropDownAddRow: {
        padding: theme.spacing(2),
    },
    headerAddButton: {
        textAlign: "left",
        justifyContent: "right",
        flexDirection: "column",
        padding: theme.spacing(1),
        [theme.breakpoints.down("md")]: {
        textAlign: "center",
        paddingBottom: theme.spacing(1)
        }
    },
    formControl: {
        width: "100%"
    },
    dropdown: {
        textAlign: "left", 
        minWidth: "200px",
        [theme.breakpoints.down("md")]: {
            minWidth: "250px"
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: "200px"
        }
    }
}));