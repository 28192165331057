import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import { Typography, TextField, InputAdornment, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

/* State */
import { useSelector, useDispatch } from "react-redux";
import { valetTicketNumberValidated } from "../../../state/slices/shiftSession/shiftSession";

/* Style */
import clsx from "clsx";
import useStyles from "./styles";

/* Utilities */
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useCredentialScanner from "../../../hooks/useCredentialScanner";
import useQueryParams from "../../../hooks/useQueryParams";
import CredentialValidators from "../Utilities/CredentialValidators";

import { TICKET_FIELD_REQUEST_TYPE } from "../../../constants";

const CashieredDeviceScanTicket = ({ classes, onTicketNumberValidated }) => {
  const scannerStyle = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = useEnqueueSnackbar();
  const queryParams = useQueryParams();

  const entityID = useSelector((state) => state.entities?.ContextID);
  const notificationStyle = useSelector((state) => {
    return {
      small: state.shiftSession.smallScreen,
      toastLocation: state.shiftSession.toastLocation,
    };
  });

  const [credentialValid, setCredentialValid] = useState();
  const [activityType, setActivityType] = useState();

  const { Reader, credentialRead } = useCredentialScanner({
    readerStyle: scannerStyle,
    ticketsOnly: false,
    toggleButtonTextOn: "Stop Scanning",
    toggleButtonTextOff: "Scan Ticket"
  });

  const credentialValidators = new CredentialValidators(apiClient);

  useEffect(() => {
    setActivityType(parseInt(queryParams["activityType"] ?? 0));
  }, []);

  useEffect(() => {
    if (!credentialRead) return;

    processTicketValidationByActivityType(credentialRead);

  }, [credentialRead]);

  const processTicketValidationByActivityType = async (ticketNumber) => {
    switch (activityType) {
      case TICKET_FIELD_REQUEST_TYPE.ARRIVAL:
        processArrivalTicketValidation(ticketNumber);
        break;
      case TICKET_FIELD_REQUEST_TYPE.REQUEST:
        processRequestTicketValidation(ticketNumber);
        break;
    }
  }

  const processArrivalTicketValidation = async (ticketNumber) => {
    const res = await credentialValidators.validateValetArrivalTicket(entityID, ticketNumber);
    if (!res) return;

    const { isValid, ticket, reasonPhrase, reason } = res;

    if (!isValid) {
      setCredentialValid(false);
      enqueueSnackbar(reasonPhrase, {
        variant: "error",
        tag: reason,
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
    } else {
      dispatch(valetTicketNumberValidated(ticket));
      onTicketNumberValidated();
    }
  }

  const processRequestTicketValidation = async (ticketNumber) => {
    const res = await credentialValidators.validateValetRequestTicket(entityID, ticketNumber);
    if (!res) return;

    const { isValid, ticket, reasonPhrase, reason } = res;

    if (!isValid) {
      setCredentialValid(false);
      enqueueSnackbar(reasonPhrase, {
        variant: "error",
        tag: reason,
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
    } else {
      dispatch(valetTicketNumberValidated(ticket));
      onTicketNumberValidated();
    }
  }

  const handleKeyDown = (e) => {
    //Should perform ticketnumber validation only when enter key is pressed
    if (e.key !== "Enter") return;
    const ticketNumber = e.target.value.trim();
    if (!ticketNumber) return;
    processTicketValidationByActivityType(ticketNumber);
  };

  return (
    <div
      className={clsx(classes.step, classes.scanCredentialStep)}
      data-testid="scan-ticket-step"
    >
      <Typography
        variant="h4"
        component="h1"
        className={classes.scanCredentialHeader}
      >
        Scan Ticket
      </Typography>
      <Reader />
      {credentialRead && (
        <div className={classes.credentialResultWrapper}>
          <Typography component="div" className={classes.credentialResultTitle}>
            Ticket Scanned
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            align="center"
            className={clsx(
              "ticket-scanned-value",
              classes.credentialResult,
              credentialValid
                ? classes.credentialValid
                : !credentialValid
                  ? classes.credentialInvalid
                  : classes.credentialScanning
            )}
          >
            {credentialRead}
          </Typography>
        </div>
      )}
      <>
        <div>Or enter ticket number below:</div>
        <div>
          <Grid container spacing={1} className={clsx("ticket-table", classes.ticketTable)} >
            <TextField
              id="ticket-number"
              className={clsx(scannerStyle.root, "ticket-searchbar", scannerStyle.searchBar)}
              label="Ticket Number"
              variant="outlined"
              size="small"
              margin="dense"
              onKeyDown={handleKeyDown}
              inputProps={{
                "data-testid": "ticket-searchbar"
              }}
              InputProps={
                (
                  {
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    ),
                  }
                )
              }
            />
          </Grid>
        </div>
      </>
    </div>
  );
};

CashieredDeviceScanTicket.defaultProps = {
  onTicketNumberValidated: () => { },
  classes: {}
};

CashieredDeviceScanTicket.propTypes = {
  classes: PropTypes.object,
  onTicketNumberValidated: PropTypes.func,
};

export default CashieredDeviceScanTicket;
