import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: `2.5px 2.5px 8px ${theme.palette.grey.light}`,
      },
      text: {
        ...theme.typography.button,
      },
    cardHeader: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.header,
        color: theme.palette.text.secondary,
        ...theme.typography.button,
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0),
        },
      },
      headerTitle: {
        textAlign: "left",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            
        },
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
      },
      headerAddButton: {
        textAlign: "right",
        justifyContent: "right",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingBottom: theme.spacing(1)
        },
      },
      headerAndTopPaginationBGColor: {
        backgroundColor: theme.palette.grey[200],
      },
      paginationHeader: {
        marginBottom: "0px",
        paddingBottom: "0px"
      },
      tableHeader: {
        ...theme.typography.button,
        whiteSpace: "nowrap",
      },
      tableCellFormat: {
        textAlign: "left",
        justifyContent: "center",
        flexDirection: "column",
      },
      centerContentColumn: {
        textAlign: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      },
      centerContentRow: {
        verticalAlign:"middle",
        justifyContent: "right",
        display: "flex",
        [theme.breakpoints.down("md")]: {
        },
      },
      tableButtons: {
        marginLeft: theme.spacing(1),
        
        [theme.breakpoints.up("md")]: {
            justifyContent: "right",
            flexDirection: "column",
            align: "right"
        }
      },
      tableScrolling: {
        height: "450px", 
        display: "flex", 
        flexDirection: "column", 
        overflowY: "scroll", 
        width: "100%"
      },
      tableCell: {
        borderBottom: "none",
        whiteSpace: "nowrap",
      },
      buttonsTableCell: {
        borderBottom: "none",
        paddingRight: theme.spacing(1),
        justifyContent: "right",
        flexDirection: "column",
        align: "right",
        [theme.breakpoints.down("md")]: {
          whiteSpace: "nowrap"
      }
      },
      tableRow: {
        borderBottom: `1px solid ${ theme.palette.header}`
      },
      hideForMobile: {
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
      },
      noDataMessage: {
        paddingTop: theme.spacing(2),
        fontSize: "1.5rem"
      },
      tableContainer: {   
        padding: "1% 3% 1% 3%"
      },
      table: {
        tableLayout: "auto"
      },
      
}));