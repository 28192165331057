import React, { useEffect, useState } from "react";
import Moment from "moment";
import Typography from "@material-ui/core/Typography";

const Clock = props => {
  const { format = "LT" } = props;
  const [time, updateTime] = useState(new Moment());

  const tick = () => {
    updateTime(new Moment());
  };

  useEffect(() => {
    const ticketInterval = setInterval(() => {
      tick();
    }, 1000);

    return () => {
      clearInterval(ticketInterval);
    };
  }, []);

  return <Typography>{time.format(format)}</Typography>;
};

export default Clock;
