import React from "react";
import PropTypes from "prop-types";

/* Components */
import {Typography, List, ListItem, ListItemText} from "@material-ui/core";

/* Style */
import clsx from "clsx";

/* State */
import useCashierOffers from "../Utilities/useCashierOffers";
import {poeOfferSelected} from "../../../state/slices/shiftSession/shiftSession";
import {useDispatch} from "react-redux";
import useCancellationToken from "../../../hooks/useCancellationToken";

/* Services */
import apiClient from "../../../auth/apiClient";
import PayOnEntryService from "../../../services/PayOnEntryService";

const payOnEntryService = new PayOnEntryService(apiClient);

const CashieredDeviceSelectOffer = ({classes, selectedOffer, onOfferSelected}) => {
  const {selectedOffers} = useCashierOffers();
  const dispatch = useDispatch();

  const {execute: createEmptyTransaction, inProgress} = useCancellationToken({
    func: async ({offer, cancelToken}) => {      
      const response = await payOnEntryService.createEmptyParkingTransactionForPOE(cancelToken);
      if (response.data != null) {
        dispatch(poeOfferSelected({offer, parkingTransactionID: response.data}));
        onOfferSelected(offer);
      }
    },
    errHandleFunc: (err) => {
      console.log(err);
    },
  });

  return (
    <div className={classes.step}>
      <Typography variant="h4" component="h1" className={classes.header}>
        Select Offer
      </Typography>
      <List
        className={clsx(classes.list, "pay-on-entry-offers")}
        data-options={selectedOffers?.map((offer) => offer.offerName)}
      >
        {selectedOffers?.map((offer) => (
          <ListItem
            key={offer.offerID}
            disabled={inProgress}
            className={clsx(classes.selectOfferButton, offer.offerName)}
            button
            selected={selectedOffer?.offerID === offer?.offerID}
            onClick={() => createEmptyTransaction({offer})}
          >
            <ListItemText primary={offer.offerName}/>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

CashieredDeviceSelectOffer.defaultProps = {
  onOfferSelected: () => {
  },
};

CashieredDeviceSelectOffer.propTypes = {
  onOfferSelected: PropTypes.func,
};

export default CashieredDeviceSelectOffer;
