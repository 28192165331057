import React, {useState} from "react";

/* Components */
import { Button, Typography } from "@material-ui/core";

/* Style */
import clsx from "clsx";

/* State */
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PAYMENT_TYPES } from "../../../constants";
import usePos from "../Utilities/usePos";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { clearTransaction } from "../../../state/slices/shiftSession/shiftSession";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";

const styles = {
  receiptContent: {
    width: "100%",
    maxWidth: "360px",
  },
  offerName: {
    marginBottom: "16px",
  },
  totalAmount: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 0px 4px 0px",
  },
  amountPaidCredit: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 0px 8px 0px",
  },
  amountPaidCash: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #999999",
    padding: "4px 0px 8px 0px",
  },
  change: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0px 8px 0px",
  },
  qrContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 0px 0px 0px",
  },
  qrCode: {
    height: "auto",
    width: "50%",
    maxWidth: "256px",
  },
};

const CashieredDeviceSummary = ({ classes, onComplete }) => {
  const paymentInformation = useSelector((state) => {
    return {
      selectedOffer: state.shiftSession.transaction.selectedOffer,
      totalCost: state.shiftSession.transaction.totalCost,
      amountPaid: state.shiftSession.transaction.amountPaid.total,
      paymentType: state.shiftSession.transaction.amountPaid.paymentType,
    };
  }, shallowEqual);
  const currentUser = useCurrentUser();
  const { complete, printReceipt: printTransactionReceipt } = usePos({
    cashierID: currentUser.UserID,
  });
  const dispatch = useDispatch();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [apiRequestInProgress, setApiRequestInProgress] = useState(false);
  const notificationStyle = useSelector((state) => {
    return {
      small: state.shiftSession.smallScreen,
      toastLocation: state.shiftSession.toastLocation,
    };
  });

  const printReceipt = async () => {
    setApiRequestInProgress(true);
    try {
      await printTransactionReceipt();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to print receipt", {
        variant: "error",
        tag: "failedToPrint",
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
    } finally {
      setApiRequestInProgress(false);
    }
  };

  const finishTransaction = async () => {
    setApiRequestInProgress(true);
    await complete();
    setApiRequestInProgress(false);
    dispatch(clearTransaction());
    onComplete();
  };

  return (
    <div
      className={clsx(classes.summaryStep, classes.step)}
      data-testid="summary-page"
    >
      <div id="receiptContent" style={styles.receiptContent}>
        <Typography
          className={clsx("offer-name")}
          align="left"
          variant="h4"
          component="h2"
          style={styles.offerName}
        >
          {paymentInformation?.selectedOffer?.offerName}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          style={styles.totalAmount}
          data-testid="totalCost"
        >
          <span className={clsx("label")}>Amount</span>
          <span
            className={clsx("value")}
          >{`$${paymentInformation?.totalCost?.toFixed(2)}`}</span>
        </Typography>
        {paymentInformation.paymentType === PAYMENT_TYPES.CreditCard && (
          <Typography
            variant="body1"
            component="div"
            style={styles.amountPaidCredit}
            data-testid="paidCredit"
          >
            <span className={clsx("label")}>Credit Paid</span>
            <span
              className={clsx("value")}
            >{`$${paymentInformation.amountPaid?.toFixed(2)}`}</span>
          </Typography>
        )}
        {paymentInformation.paymentType === PAYMENT_TYPES.Cash && (
          <>
            <Typography
              variant="body1"
              component="div"
              style={styles.amountPaidCash}
              data-testid="paidCash"
            >
              <span className={clsx("label")}>Cash Paid</span>
              <span
                className={clsx("value")}
              >{`$${paymentInformation.amountPaid}`}</span>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              style={styles.change}
              data-testid="changeDue"
            >
              <span className={clsx("label")}>Change</span>
              <span className={clsx("value")}>{`$${(
                paymentInformation.amountPaid - paymentInformation?.totalCost
              )?.toFixed(2)}`}</span>
            </Typography>
          </>
        )}
      </div>
      <div className={classes.summaryButtonsWrapper}>
        <Button
          variant="outlined"
          onClick={printReceipt}
          disabled={apiRequestInProgress}
          className={clsx(classes.printButton, "print-receipt")}
        >
          Print Receipt
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={apiRequestInProgress}
          onClick={finishTransaction}
          className={clsx(classes.summaryCompleteButton, "complete")}
          data-testid="complete"
        >
          Complete
        </Button>
      </div>
    </div>
  );
};

export default CashieredDeviceSummary;
