import { useSelector } from "react-redux";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import useHasPermissions from "../../../hooks/useHasPermissions";
import usePoeState from "../../../state/slices/POE/usePoeState";

const useCashierOffers = () => {
  const cashieredDeviceID = useSelector(
    (state) => state.shiftSession.cashieredDeviceID
  );
  const { facilityID } = useCurrentFacility();
  const { hasPermissions } = useHasPermissions();
  const PayOnEntryCashier = hasPermissions(["cashiermodes.payonentrycashier"]);
  const RoamingCashier = hasPermissions(["cashiermodes.roamingcashier"]);
  const { selectedOffers } = usePoeState({
    entityID: getOfferEntityID(),
  });

  function getOfferEntityID() {
    if (
      RoamingCashier &&
      (!cashieredDeviceID || cashieredDeviceID === "-1") &&
      facilityID
    ) {
      return facilityID;
    } else if (
      PayOnEntryCashier &&
      cashieredDeviceID &&
      cashieredDeviceID !== "-1"
    ) {
      return cashieredDeviceID;
    }
  }

  return { selectedOffers };
};

export default useCashierOffers;
