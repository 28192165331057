import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CardFormatPicker from "./CardFormatPicker/CardFormatPicker";
import { Typography, Grid, Divider } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";
import CardFormatService from "../../services/CardFormatService";
import apiClient from "../../auth/apiClient";
import CardFormatList from "./CardFormatList/CardFormatList";
import { useEnqueueSnackbar } from "./../../hooks/useEnqueueSnackbar";
import useHasPermissions from "./../../hooks/useHasPermissions";
import _ from "lodash";

const cardFormatService = new CardFormatService(apiClient);

const CardFormats = ({ entityID }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [availableCardFormats, setAvailableCardFormats] = useState([]);
  const { hasPermissions } = useHasPermissions();
  const editSettingsPermission = hasPermissions([
    "overview.facilities.editsettings"
  ]);
  const [entityCardFormats, setEntityCardFormats] = useState([]);
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);
  const facilityID = facilityGroupId || entityID;

  const handleAddFormat = async format => {
    let exists = entityCardFormats.some(x => x.formatName == format.formatName);

    if (exists) {
      enqueueSnackbar("Card Format is already active for this facility", {
        variant: "warning"
      });
      return false;
    }

    try {
      var result = await cardFormatService.createEntityCardFormat({
        EntityID: facilityID,
        CardFormatID: format.cardFormatID
      });
      let arr = [...entityCardFormats];
      arr.push(result.data);
      addFormatName(arr);
      setEntityCardFormats([...arr]);
      return true;
    } catch (error) {
      enqueueSnackbar("Failed to add card format to facility", {
        variant: "error"
      });
    }
    return false;
  };

  const handleDelete = async formatID => {
    console.log("Deleting", formatID);
    try {
      await cardFormatService.deleteEntityCardFormat(facilityID, formatID);
      setEntityCardFormats([
        ...entityCardFormats.filter(x => x.cardFormatID != formatID)
      ]);
      enqueueSnackbar("Successfully removed card format", {
        variant: "success"
      });
    } catch (err) {
      enqueueSnackbar("Failed to remove card format", { variant: "error" });
    }
  };

  const getCardFormats = async () => {
    try {
      var result = await cardFormatService.getCardFormats();
      var sortedFormats = _.orderBy(result.data, [ 'formatName'], ['asc']);
      setAvailableCardFormats([...sortedFormats]);
    } catch (error) {
      enqueueSnackbar("Failed to get existing card formats for facility", {
        variant: "error"
      });
    }
  };

  const getEntityCardFormats = async () => {
    try {
      var result = await cardFormatService.getEntityCardFormats(facilityID);
      addFormatName(result.data);
      var sortedFormats = _.orderBy(result.data, [ 'formatName'], ['asc']);
      setEntityCardFormats([...sortedFormats]);
    } catch (error) {
      enqueueSnackbar("Failed to get existing card formats for facility", {
        variant: "error"
      });
    }
  };

  const addFormatName = arr => {
    var formats = [...availableCardFormats];
    var listToUpdate = [...arr];

    listToUpdate.forEach(row => {
      row.formatName = formats.find(
        x => x.cardFormatID == row?.cardFormatID
      )?.formatName;
    });
  };

  useEffect(() => {
    getCardFormats();
  }, [entityID, facilityGroupId]);

  useEffect(() => {
    getEntityCardFormats();
  }, [availableCardFormats]);

  return (
    <>
      <Grid container className={clsx(`title-container`, "card-formats-title", classes.container)}>
        <Grid item xs={12} mg={12} lg={12}>
          <Typography
            variant="h5"
            color="primary"
            component="h2"
            className={clsx("title")}
          >
            Card Formats
          </Typography>
          <Divider style={{ marginBottom: 15 }}></Divider>
        </Grid>
      </Grid>
      <Grid container className={clsx("card-formats-container")}>
        {editSettingsPermission ? (
          <>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className={clsx("dropdown-container", classes.alignContentRight)}
            >
              <CardFormatPicker
                handleAddButton={handleAddFormat}
                existingCardFormats={[...availableCardFormats]}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className={clsx("list-container")}
            >
              <CardFormatList
                cardFormatArray={[...entityCardFormats]}
                entityID={entityID}
                handleDeleteButton={handleDelete}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={12} lg={12}>
            <Typography align="center" className={clsx("no-permissions-text")}>
              You do not have permission to view card formats.
            </Typography>
          </Grid>
        )}

      </Grid>
    </>
  );
};

export default CardFormats;
