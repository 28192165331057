import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import { Typography } from "@material-ui/core";
import TicketSearch from "../TicketSearch";

/* State */
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useSelector, useDispatch } from "react-redux";
import { credentialValidated } from "../../../state/slices/shiftSession/shiftSession";

/* Style */
import clsx from "clsx";
import useStyles from "./styles";

/* Utilities */
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import CredentialValidators from "../Utilities/CredentialValidators";
import useCredentialScanner from "../../../hooks/useCredentialScanner";
import { useHistory } from "react-router-dom";

/* Constants */
const urlRegex =
  "^(https?://)?([A-Za-z-.]){1}(:[0-9]{2,5})?.*/mobilepay/[0-9A-Fa-f]{8}[-]?(?:[0-9A-Fa-f]{4}[-]?){3}[0-9A-Fa-f]{12}";
const guidRegex =
  "[({]?[a-fA-F0-9]{8}[-]?([a-fA-F0-9]{4}[-]?){3}[a-fA-F0-9]{12}[})]?";

const credentialValidators = new CredentialValidators(apiClient);

const CashieredDeviceScanCredential = ({ classes, onCredentialValidated }) => {
  const scannerStyle = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [credentialValid, setCredentialValid] = useState();
  const { facilityID } = useCurrentFacility();
  const cashieredDeviceID = useSelector(
    (state) => state.shiftSession?.cashieredDeviceID
  );
  const cashieredShiftID = useSelector(
    (state) => state.shiftSession?.shiftID
  );
  const cashieredShiftFacilityID = useSelector(
    (state) => state.shiftSession?.entityID
  );
  const isAttendedDevice = cashieredDeviceID && cashieredDeviceID !== "-1";
  const enqueueSnackbar = useEnqueueSnackbar();
  const notificationStyle = useSelector((state) => {
    return {
      small: state.shiftSession.smallScreen,
      toastLocation: state.shiftSession.toastLocation,
    };
  });
  const {
    Reader,
    credentialRead,
    forceScan,
  } = useCredentialScanner({
    readerStyle: scannerStyle,
    ticketsOnly: false,
    toggleButtonTextOn: "Stop Scanning",
    toggleButtonTextOff: "Scan for Credential"
  });

  useEffect(() => {
    if (!credentialRead) return;

    selectTypeOfCredential(credentialRead, cashieredDeviceID, cashieredShiftFacilityID, isAttendedDevice, cashieredShiftID);
  }, [isAttendedDevice, cashieredDeviceID, credentialRead]);

  const selectTypeOfCredential = async (credRead, shiftDeviceID, shiftEntityID, attended, shiftID) => {
    const ticketParseResult = new RegExp(urlRegex, "g").exec(credRead);
    const barcodeResult = new RegExp(guidRegex, "g").exec(credRead);
    if (ticketParseResult) {
      if (barcodeResult && !attended) {
        handleTicketValidation(barcodeResult[0]);
      } else {
        enqueueSnackbar("Tickets are not allowed", {
          variant: "error",
          tag: ""
        });
      }
    } else if (barcodeResult) {
      handleCredentialValidation(barcodeResult[0], shiftDeviceID, shiftEntityID, attended, shiftID);
    } else {
      handleCredentialValidation(credRead, shiftDeviceID, shiftEntityID, attended, shiftID);
    }
  }

  const handleTicketValidation = async (credentialRead) => {
    const res = await credentialValidators.validateTicket(credentialRead, facilityID);
    if (!res) return;
    const {
      isValid,
      reasonPhrase,
      reason,
      ticket,
      ticketRef,
      alreadyPaid,
    } = res;
    if (!isValid) {
      setCredentialValid(false);
      enqueueSnackbar(reasonPhrase, {
        variant: "error",
        tag: reason,
        title: `TicketID ${ticketRef ?? credentialRead}`,
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
    } else {
      dispatch(credentialValidated(ticket));
      onCredentialValidated({
        zeroFee: alreadyPaid,
        credential: ticket,
      });
    }
  };

  const handleCredentialValidation = async (credentialRead, shiftDeviceID, shiftEntityID, attended, shiftID) => {
    const res = await credentialValidators.validateCredentialForCashier(
      credentialRead, shiftDeviceID, shiftEntityID, attended, shiftID
    );
    const { isValid, reasonPhrase, reason, credential } = res;
    if (!isValid) {
      setCredentialValid(false);
      enqueueSnackbar(reasonPhrase, {
        variant: "error",
        tag: reason,
        title: `Credential ID: ${credentialRead}`,
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
    } else {
      dispatch(credentialValidated(credential));
      onCredentialValidated({ prepaid: true, credential: credential });
    }
  };

  return (
    <div
      className={clsx(classes.step, classes.scanCredentialStep)}
      data-testid="scan-credential-step"
    >
      <Typography
        variant="h4"
        component="h1"
        className={classes.scanCredentialHeader}
      >
        {"Scan Credential"}
      </Typography>
      <Reader />
      {credentialRead && (
        <div className={classes.credentialResultWrapper}>
          <Typography component="div" className={classes.credentialResultTitle}>
            Credential Scanned
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            align="center"
            className={clsx(
              "credential-scanned-value",
              classes.credentialResult,
              credentialValid
                ? classes.credentialValid
                : !credentialValid
                  ? classes.credentialInvalid
                  : classes.credentialScanning
            )}
          >
            {credentialRead}
          </Typography>
          {credentialValid === true && (
            <Typography
              variant="subtitle1"
              component="div"
              align="center"
              className={clsx("access-granted", classes.credentialValid)}
            >
              Access Granted
            </Typography>
          )}
        </div>
      )}
      {!isAttendedDevice && (
        <>
          <div>Or enter ticket number below:</div>
          <div>
            <TicketSearch
              facilityID={facilityID}
              itemLimit={50}
              onTicketClick={handleTicketValidation}
            />
          </div>
        </>
      )}
    </div>
  );
};

CashieredDeviceScanCredential.defaultProps = {
  onCredentialValidated: () => { },
};

CashieredDeviceScanCredential.propTypes = {
  onCredentialValidated: PropTypes.func,
};

export default CashieredDeviceScanCredential;
