import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    text: {
      ...theme.typography.button,
    },
    container: {
      padding: theme.spacing(2),
    },
    centerContentColumn: {
      textAlign: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    tableButtons: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
          justifyContent: "right",
          flexDirection: "column",
          align: "right"
      }
    },
    noDataMessage: {
      paddingTop: theme.spacing(2),
      fontSize: "1.5rem"
    },
    tableContainer: {   
      padding: "1% 3% 1% 3%"
    },
    table: {
      tableLayout: "auto"
    },

}));