import React from "react";

/* Components */
import { Button, Typography } from "@material-ui/core";

/* Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays as calendarIcon,
  faBarcode as barcodeIcon,
  faTicket as ticketIcon,
  faBellExclamation as requestIcon,
} from "@fortawesome/pro-regular-svg-icons";

/* Style */
import clsx from "clsx";

/* State */
import useCashierOffers from "../Utilities/useCashierOffers";
import { TICKET_FIELD_REQUEST_TYPE } from "../../../constants";

const CashieredDeviceSelectEntryType = ({
  classes,
  onSelectOfferClick,
  onScanCredentialClick,
  onScanTicketClick,
  isValetHandheldSelected,
}) => {

  const { selectedOffers } = useCashierOffers();
  const hasNoOffers = selectedOffers === undefined || selectedOffers?.length < 1;

  return (
    <div className={classes.step} data-testid="start-step">
      {!hasNoOffers && !isValetHandheldSelected && (
        <Button
          data-testid="selectOffer"
          className={clsx(
            classes.entryChoiceButton,
            classes.offersChoiceButton
          )}
          disabled={hasNoOffers}
          onClick={onSelectOfferClick}
        >
          <FontAwesomeIcon
            className={classes.entryChoiceButtonIcon}
            icon={calendarIcon}
          ></FontAwesomeIcon>
          <div className={classes.entryChoiceButtonText}>
            <Typography variant="h5" component="div">
              Offers
            </Typography>
            <Typography variant="subtitle1" component="div">
              Select from a list of pre-pay events / offers.
            </Typography>
          </div>
        </Button>
      )}
      {!isValetHandheldSelected && (
        <Button
          data-testid="scanCredential"
          className={clsx(
            classes.entryChoiceButton,
            classes.credentialChoiceButton
          )}
          onClick={onScanCredentialClick}
        >
          <FontAwesomeIcon
            className={classes.entryChoiceButtonIcon}
            icon={barcodeIcon}
          ></FontAwesomeIcon>
          <div className={classes.entryChoiceButtonText}>
            <Typography variant="h5" component="div">
              Scan Credential
            </Typography>
            <Typography variant="subtitle1" component="div">
              Scan a credential presented by the customer.
            </Typography>
          </div>
        </Button>
      )}
      {isValetHandheldSelected && (
        <>
          <Button
            data-testid="arrival"
            className={clsx(classes.entryChoiceButton, classes.arrivalChoiceButton)}
            onClick={() => onScanTicketClick(TICKET_FIELD_REQUEST_TYPE.ARRIVAL)}
          >
            <FontAwesomeIcon
              className={classes.entryChoiceButtonIcon}
              icon={ticketIcon}
            />
            <div className={classes.entryChoiceButtonText}>
              <Typography variant="h5" component="div">
                Arrival
              </Typography>
              <Typography variant="subtitle1" component="div">
                Scan valet ticket to arrive a car.
              </Typography>
            </div>
          </Button>

          <Button
            data-testid="request"
            className={clsx(classes.entryChoiceButton, classes.arrivalChoiceButton)}
            onClick={() => onScanTicketClick(TICKET_FIELD_REQUEST_TYPE.REQUEST)}
          >
            <FontAwesomeIcon
              className={classes.entryChoiceButtonIcon}
              icon={requestIcon}
            />
            <div className={classes.entryChoiceButtonText}>
              <Typography variant="h5" component="div">
                Pay Request
              </Typography>
              <Typography variant="subtitle1" component="div">
                Scan valet ticket to request a car.
              </Typography>
            </div>
          </Button>
        </>
      )}
    </div>
  );
};
export default CashieredDeviceSelectEntryType;
