import React from "react";
import PropTypes from "prop-types";

/* Components */
import {
  Typography,
  Button,
  TextField,
  InputAdornment
} from "@material-ui/core";

/* State */
import { useDispatch } from "react-redux";
import { addAdjustedFee } from "../../../state/slices/shiftSession/shiftSession";
import useStateRef from "../../../hooks/useStateRef";

/* Style */
import clsx from "clsx";

export const processInputFee = (inputFee) => {
  if (inputFee.toString().length > 10) {
    return null;
  }

  if (isNaN(inputFee)) {
    return null;
  }

  let feeValue = inputFee * 1;   // removes leading 0
  let str = feeValue.toString();

  // handles trailing 0
  if (feeValue > 0 && inputFee.length > 1) {
    let tmp = 0;
    for (let i = inputFee.length - 1; i > 0; --i) {
      if (inputFee[i] == 0)
        tmp++;
      else
        break;
    }
    if (tmp > 0)
      str = str.concat('0'.repeat(tmp));
  }

  feeValue = str.replace('.', '');
  return (feeValue / 100).toFixed(2).toString();
}

export const acceptedKey = (key) => {
  if (key >= 0 && key <= 9) {
    return true;
  }
  if (key === "Backspace" || key === "Delete") {
    return true;
  }
  if (key === "ArrowLeft" || key === "ArrowRight"
    || key === "Home" || key === "End") {
    return true;
  }
  return false;
}

const CashieredDeviceAdjustFee = ({ classes, onAdjustFeeComplete }) => {
  const [adjustedFee, setAdjustedFee] = useStateRef("0.00");
  const dispatch = useDispatch();

  const useAdjustedFee = async () => {
    dispatch(addAdjustedFee(adjustedFee.current));
    onAdjustFeeComplete();
  };

  return (
    <div className={classes.step} data-testid="adjust-fee-step">
      <Typography
        variant="h4"
        component="h1"
        className={clsx("adjust-fee-header", classes.header)}
        align="center"
      >
        Adjusted Fee
      </Typography>
      <TextField
        variant="outlined"
        align="center"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        inputProps={{
          maxLength: 10,
          "data-testid": "adjusted-fee-text-field",
        }}
        value={adjustedFee.current}
        className={clsx("adjust-fee-text", classes.adjustFeeText)}
        onChange={(event) => {
          let processedInputFee = processInputFee(event.target.value)
          if (processedInputFee) {
            setAdjustedFee(processedInputFee);
          } else {
            event.preventDefault(); // throw away unwanted value
          }
        }}
        onKeyDown={(event) => {
          if (acceptedKey(event.key) == false) {
            event.preventDefault(); // throw away unwanted value
          }
        }}
        onPaste={(event) => {
          // Don't support paste 
          event.preventDefault();
        }}
      />
      <Button
        color="primary"
        onClick={useAdjustedFee}
        variant="contained"
        data-testid={`btn-complete`}
        className={clsx("adjust-fee-complete-btn", classes.adjustFeeCompleteButton)}
        disabled={!(adjustedFee.current > 0)}
      >
        Complete
      </Button>
    </div>
  );
};

CashieredDeviceAdjustFee.defaultProps = {
  onAdjustFeeComplete: () => {
  },
};

CashieredDeviceAdjustFee.propTypes = {
  onAdjustFeeComplete: PropTypes.func,
};

export default CashieredDeviceAdjustFee;
