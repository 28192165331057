import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import moment from "moment";

/* Components */
import {
  List,
  Typography,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";

import MultiValidationOfferDropdown from "../../Dropdowns/MultiValidationOfferDropdown";
import {NavConfirmation} from "../../UserConfirmation";

/* Icons */
import DeleteIcon from "@material-ui/icons/Delete";

/* Utilities */
import useHasPermissions from "../../../hooks/useHasPermissions";
import useCredentialScanner from "../../../hooks/useCredentialScanner";
import {useEnqueueSnackbar} from "../../../hooks/useEnqueueSnackbar";
import CredentialValidators from "../Utilities/CredentialValidators";
import ValidationOfferRetriever from "../Utilities/ValidationOfferRetriever";
import RateCalculators from "../Utilities/RateCalculators";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";

/* Style */
import clsx from "clsx";
import useStyles from "./styles";

/* State */
import {useDispatch, useSelector} from "react-redux";
import {
  feeCalculated,
  setValidationNavigation,
  unclaimedValidation,
  addClaimedValidation,
} from "../../../state/slices/shiftSession/shiftSession";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import useCurrentUser from "../../../hooks/useCurrentUser";

/* Services */
import apiClient from "../../../auth/apiClient";

/* Constants */
import {VALIDATION_TYPES_ENUM} from "../../../constants";
import usePos, {ResponseTypes} from "../Utilities/usePos";
import CashierService from "../../../services/CashierService";
import CredentialService from "../../../services/CredentialService";

const formDescriptionText = (foundValOffer) => {
  const formattedAmount = foundValOffer?.validationAmount?.toFixed(2);
  switch (foundValOffer.validationTypeID) {
    case VALIDATION_TYPES_ENUM["Amount"]:
      return `$${-1 * formattedAmount} off`;
    case VALIDATION_TYPES_ENUM["Flat Fee"]:
      return `Flat Fee of $${formattedAmount}`;
    case VALIDATION_TYPES_ENUM["Percentage"]:
      return `${-1 * formattedAmount}% off`;
    case VALIDATION_TYPES_ENUM["Rate Switch"]:
      return `Rate was changed to ${foundValOffer.validationOfferName}`;
    case VALIDATION_TYPES_ENUM["Adjust Entry Time"]:
      return `Entry Time moved forward ${-1 * formattedAmount} minutes`;
    case VALIDATION_TYPES_ENUM["Adjust Exit Time"]:
      return `Exit Time moved backward ${-1 * formattedAmount} minutes`;
  }
};

const credentialValidators = new CredentialValidators(apiClient);
const validationOfferRetriever = new ValidationOfferRetriever(apiClient);
const rateCalculators = new RateCalculators(apiClient);
const cashierService = new CashierService(apiClient);
const credentialService = new CredentialService(apiClient);

const defaultLoadingState = {isLoading: false, message: ""};

const CashieredDeviceScanDiscounts = ({classes, onPaymentBypass}) => {
  const scannerStyle = useStyles();
  const cashieredDeviceID = useSelector((state) => state.shiftSession?.cashieredDeviceID);
  const isAttendedDevice = cashieredDeviceID && cashieredDeviceID !== "-1";
  const {hasPermissions} = useHasPermissions();
  const ValidateTicketsPermission = hasPermissions(["validatetickets"]);
  const [selectedValOffer, setSelectedValOffer] = useState(null);
  const [validationOffers, setValidationOffers] = useState([]);
  const [hasValidationOffers, setHasValidationOffers] = useState(false);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [rateNeedsRecalculated, setRateNeedsRecalculated] = useState(false);
  const [apiRequestInProgress, setApiRequestInProgress] = useState(false);
  const {facilityID} = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const currentUser = useCurrentUser();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [scannedValidationText, setScannedValidationText] = useState();
  const [isValidationValid, setIsValidationValid] = useState();
  const dispatch = useDispatch();
  const {timeZone} = useCurrentFacilityTimezone();
  const [loading, setLoading] = useState(defaultLoadingState);
  const [canShowPrompt, setCanShowPrompt] = useState(false);
  const selectedOffer = useSelector((state) => state.shiftSession.transaction.selectedOffer);
  const scannedCredential = useSelector((state) => state.shiftSession.transaction.credential);
  const rateTotalCost = useSelector((state) => state.shiftSession.transaction.totalCost);
  const parkingTransactionID = useSelector((state) =>
    state.shiftSession.transaction.credential?.parkingTransactionID ?? state.shiftSession.transaction.parkingTransactionID);
  const discountsApplied = useSelector((state) => state.shiftSession.transaction.validations);
  const adjustedFee = useSelector((state) => state.shiftSession.transaction.adjustedFee);
  const notificationStyle = useSelector((state) => {
    return {
      small: state.shiftSession.smallScreen,
      toastLocation: state.shiftSession.toastLocation,
    };
  });
  const {
    Reader,
    credentialRead,
    forceScan,
  } = useCredentialScanner({
    readerStyle: scannerStyle,
    ticketsOnly: !isAttendedDevice,
    toggleButtonTextOn: "Stop Scanning",
    toggleButtonTextOff: "Scan for Discounts"
  });
  const {processCashPayment} = usePos({
    facilityID,
    cashierID: currentUser.UserID,
    onTransactionChanged: handleTransactionChanged,
  });

  useEffect(() => {
    if (!credentialRead) return;
    verifyValidation(credentialRead);
  }, [credentialRead]);

  useEffect(() => {
    if (currentUser.UserID && facilityID)
      getValidationOffers(facilityID, currentUser.UserID, selectedOffer, scopeAwareFacilityID);
  }, [currentUser.UserID, facilityID, selectedOffer, scopeAwareFacilityID]);

  useEffect(() => {
    const apiInProgress = validationInProgress === true || rateNeedsRecalculated === true;
    setApiRequestInProgress(apiInProgress)
    setCanShowPrompt(apiInProgress)
  }, [validationInProgress, rateNeedsRecalculated]);

  useEffect(() => {
    const enableBackButton = apiRequestInProgress === false && loading.isLoading === false;
    const enableNextButton = discountsApplied?.length > 0 && enableBackButton;
    const shouldCompletePayment = enableNextButton && rateTotalCost === 0;

    if (shouldCompletePayment) {
      completePayment();
    }

    dispatch(setValidationNavigation({
      enableNext: enableNextButton,
      enableBack: enableBackButton,
    }));
  }, [discountsApplied, rateTotalCost, apiRequestInProgress, loading]);

  useEffect(() => {
    if (rateNeedsRecalculated) {
      recalculateRate();
    }
  }, [discountsApplied, rateNeedsRecalculated]);

  const showError = (message, tag) => {
    enqueueSnackbar(message, {
      variant: "error",
      tag: tag,
      anchorOrigin: notificationStyle.toastLocation,
      fullwidth: notificationStyle.small,
    });
  }

  function handleTransactionChanged(transaction) {
    if (transaction.Status === ResponseTypes.Failed) {
      setCanShowPrompt(false);
      showError("Failed to process validation. Please retry.", null);
      setLoading(defaultLoadingState);
    }
    if (transaction.Status === ResponseTypes.Complete) {
      setCanShowPrompt(false);
      onPaymentBypass();
    }
  }

  const verifyValidation = async (validationID) => {
    setValidationInProgress(true);
    const res = await credentialValidators.verifyValidation(
      validationID,
      facilityID, // leave as facilityID as we need to validate it's in the AcceptedAtEntityIDs list
      discountsApplied,
      selectedOffer,
      scopeAwareFacilityID
    );
    const {isValid, reasonPhrase, validation} = res;
    if (!isValid) {
      setScannedValidationText(reasonPhrase);
    } else {
      try {
        const claimResult = await cashierService.claimCashierValidation({
          cashierID: currentUser.UserID,
          parkingTransactionID: parkingTransactionID,
          validationID: validation.validationsInventoryID,
          offerName: validation.validationOfferName,
        });
        const success = claimResult?.data?.success === true
        if (!success) {
          showError("Unable to claim validation", "UnableToClaimValidation");
          setValidationInProgress(false);
          return;
        }

        dispatch(addClaimedValidation(claimResult.data));
        setScannedValidationText(validationID);
        setIsValidationValid(true);
        setRateNeedsRecalculated(true);
      } catch (err) {
        console.error("Add Validation Error: ", err);
        showError("Unable to claim validation", "UnableToClaimValidation");
      }
    }
    setValidationInProgress(false);
  };

  const getValidationOffers = async (facilityID, userID, selectedOffer, scopeAwareFacilityID) => {
    setValidationInProgress(true);
    const res = await validationOfferRetriever.getValidationOffers(facilityID, userID, selectedOffer, scopeAwareFacilityID);
    setValidationInProgress(false);
    const {success, offers, reason, reasonPhrase} = res;
    if (!success) {
      showError(reasonPhrase, reason);
    } else {
      if (offers && offers.length > 0 && offers.some(x => x.offers?.length > 0)) {
        setHasValidationOffers(true);
        setValidationOffers(offers);
      } else {
        setHasValidationOffers(false);
        setValidationOffers();
      }
    }
  };

  const addValidation = async () => {
    if (!selectedValOffer) return;

    try {
      setValidationInProgress(true);
      const response = await credentialService.BulkCreateValidations({
        validationOffer: selectedValOffer.id,
        quantity: 1,
        validFrom: moment.min().format("YYYY-MM-DDTHH:mm:ssZ"),
        validUntil: moment.max().format("YYYY-MM-DDTHH:mm:ssZ"),
        maxUses: 1,
        maxUsesPerTransaction: 1,
        kind: "Online",
        format: "QR Code",
        userID: currentUser.UserID,
        cashierID: currentUser.UserID,
        parkingTransactionID: parkingTransactionID,
      });
      const createdValidation = response.data[0];
      const claimed = await cashierService.claimCashierValidation({
        cashierID: currentUser.UserID,
        parkingTransactionID: parkingTransactionID,
        validationID: createdValidation.id,
        offerName: createdValidation.validationOfferName,
      });

      const result = claimed?.data?.success === true ? claimed.data : null;
      if (!result.success) {
        showError("Unable to apply validation", "UnableToApplyValidation");

        setValidationInProgress(false);
        return;
      }

      dispatch(addClaimedValidation(claimed.data));
      setRateNeedsRecalculated(true);
    } catch (err) {
      console.error("Add Validation Error: ", err);
      showError("Unable to apply validation", "UnableToApplyValidation");
    }

    setValidationInProgress(false);
  };

  const recalculateRate = async () => {
    let rate;
    if (selectedOffer) {
      const res = await rateCalculators.calculatePOERate(
        currentUser.UserID,
        selectedOffer,
        timeZone,
        discountsApplied,
        adjustedFee
      );
      rate = res.rate;
    } else {
      rate = await rateCalculators.calculateRateForTicket(
        scannedCredential,
        currentUser.UserID
      );
    }

    if (!rate) {
      showError("Unable to recalculate rate", "UnableToRecalculateRate");
      setRateNeedsRecalculated(false);
      return;
    }

    dispatch(feeCalculated({
      rateReceipt: rate,
      rateValidUntil: rate.exitTime,
      totalCost: rate.totalToPay,
      initialTotal: rate.baseFee,
    }));
    setRateNeedsRecalculated(false);
  };

  const completePayment = async () => {
    try {
      setCanShowPrompt(true);
      setLoading({isLoading: true, message: "Processing Payment..."});
      await processCashPayment(0, 0);
    } catch (error) {
      setCanShowPrompt(false);
      setLoading(defaultLoadingState);
      showError(error.message, "UnableToProcessPayment");
    }
  };

  const deleteValidation = async (validation) => {
    try {
      setValidationInProgress(true);
      await cashierService.unclaimCashierValidation({
        cashierID: currentUser.UserID,
        validationID: validation.validationsInventoryID,
        parkingTransactionID: parkingTransactionID,
      });
      dispatch(unclaimedValidation(validation.validationsInventoryID));
      setRateNeedsRecalculated(true);
    } catch (err) {
      console.error(err);
      showError("Unable to unapply validation", "UnableToUnapplyValidation");
    }
    setValidationInProgress(false);
  };

  return (
    <div className={classes.step} data-testid="scan-discount-step">
      <NavConfirmation
        when={canShowPrompt}
        title="Warning!"
        message={loading.isLoading ?
          "Please complete your transaction before leaving the page." :
          "Please wait until discounts have been applied before leaving the page."}
        buttons={[
          {
            text: "Ok",
            attribute: "discount-navigation-ok-btn",
            state: false,
            color: "primary",
            order: 0,
          },
        ]}
      />
      {loading.isLoading && (
        <>
          <CircularProgress/>
          <Typography
            variant="h5"
            component="div"
            align="center"
            data-testid="circular-progress-loader"
          >
            {loading.message}
          </Typography>
        </>
      )}
      {!loading.isLoading && (
        <div
          onPaste={(e) => forceScan(e.clipboardData.getData("Text"))}
          data-testid="discount-scan-container"
        >
          <Typography variant="h4" component="h1">
            Scan Discounts
          </Typography>
          <Reader/>
          {scannedValidationText && (
            <div className={classes.credentialResultWrapper}>
              <Typography
                component="div"
                className={classes.credentialResultTitle}
              >
                {credentialRead}
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                align="center"
                data-testid="scanmessage"
                className={clsx(
                  classes.credentialResult,
                  isValidationValid
                    ? classes.credentialValid
                    : !isValidationValid
                      ? classes.credentialInvalid
                      : classes.credentialScanning
                )}
              >
                {scannedValidationText}
              </Typography>
            </div>
          )}
          {ValidateTicketsPermission && (
            <>
              <div className={classes.validate}>
                <MultiValidationOfferDropdown
                  className={clsx(`select-val-offer`, classes.multiValDropdown)}
                  validationOffers={validationOffers}
                  onChange={(offer) => {
                    setSelectedValOffer(offer);
                  }}
                />
                {hasValidationOffers && (
                <Button
                  className={clsx([`btn-applyval`, `apply`, classes.applyButton])}
                  color="primary"
                  onClick={addValidation}
                  variant="contained"
                  disabled={selectedValOffer === undefined || selectedValOffer === null || apiRequestInProgress}
                  data-testid={`btn-applyval`}>
                  Apply
                </Button>
                )}
              </div>
            </>
          )}
          <List
            dense={true}
            className={clsx([
              "applied-discounts",
              classes.appliedValidationlist,
            ])}
          >
            {discountsApplied?.map((discount) => (
              <ListItem
                key={discount.id}
                className={clsx(`${discount.validationOfferName}-discount`)}
              >
                <ListItemText
                  primary={discount.validationOfferName}
                  secondary={formDescriptionText(discount)}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    className={clsx(
                      "delete-discount",
                      discount.validationOfferName
                    )}
                    edge="end"
                    onClick={() => deleteValidation(discount)}
                    aria-label="delete"
                    disabled={apiRequestInProgress}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {apiRequestInProgress && (
              <ListItem className={clsx(scannerStyle.validationSpinner)}>
                <CircularProgress></CircularProgress>
              </ListItem>
            )}
          </List>
        </div>
      )}
    </div>
  );
};

CashieredDeviceScanDiscounts.defaultProps = {
  onPaymentBypass: () => {
  },
};

CashieredDeviceScanDiscounts.propTypes = {
  onPaymentBypass: PropTypes.func,
};

export default CashieredDeviceScanDiscounts;
