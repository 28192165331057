import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/* Components */
import {
  Button,
  Typography,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import CashieredDeviceCostBreakdown from "./CostBreakdown";

/* Style */
import clsx from "clsx";

/* Utilities */
import usePos from "../Utilities/usePos";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import RateCalculators from "../Utilities/RateCalculators";
import apiClient from "../../../auth/apiClient";

/* State */
import { useDispatch, useSelector } from "react-redux";
import {
  clearTransaction,
  feeCalculated,
  startTransaction as startTransactionState,
} from "../../../state/slices/shiftSession/shiftSession";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";

const rateCalculators = new RateCalculators(apiClient);

const CashieredDeviceChoosePaymentType = ({
  classes,
  onPayCash,
  onPayCredit,
  onDiscounts,
  onAdjustFee,
  onCancel,
  onInitialFeeCalculation,
}) => {
  const totalCost = useSelector(
    (state) => state.shiftSession.transaction.totalCost
  );
  const ccTerminalID = useSelector((state) => state.shiftSession.ccterminalId);
  const noActiveCreditCardTerminal =
    ccTerminalID === null || ccTerminalID === 0 || ccTerminalID === -1;
  const dispatch = useDispatch();
  const { facilityID } = useCurrentFacility();
  const currentUser = useCurrentUser();
  const { startTransaction, cancelTransaction } = usePos({
    posTerminalID: ccTerminalID,
    facilityID,
    cashierID: currentUser.UserID,
  });
  const transactionData = useSelector((state) => {
    const transaction = state.shiftSession.transaction;
    return {
      selectedOffer: transaction.selectedOffer,
      parkingTransactionID: transaction.parkingTransactionID,
      started: transaction.started,
      validations: transaction.validations,
      credential: transaction.credential,
      adjustedFee: transaction.adjustedFee,
    };
  });
  const notificationStyle = useSelector((state) => {
    return {
      small: state.shiftSession.smallScreen,
      toastLocation: state.shiftSession.toastLocation,
    };
  });
  const enqueueSnackbar = useEnqueueSnackbar();
  const [loading, setIsLoading] = useState({ isLoading: true, message: "" });
  const [apiRequestInProgress, setApiRequestInProgress] = useState(false);
  const [rateCalculationInProgress, setRateCalculationInProgress] = useState(false);
  const { timeZone } = useCurrentFacilityTimezone();
  
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!transactionData.started && transactionData.parkingTransactionID)
      initiateTransaction(
        transactionData.selectedOffer,
        transactionData.parkingTransactionID
      );
  }, [transactionData.parkingTransactionID]);

  useEffect(() => {
    if (transactionData.started && transactionData.selectedOffer) {
      calculatePOERate(transactionData.selectedOffer);
    }
  }, [transactionData.started, transactionData.selectedOffer]);

  useEffect(() => {
    if (transactionData.started && transactionData.credential) {
      calculateTicketRate(transactionData.credential);
    }
  }, [transactionData.started, transactionData.credential]);

  useEffect(() => {
    if (totalCost) setIsLoading({ isLoading: false });
  }, [totalCost]);

  const calculateTicketRate = async (ticket) => {
    setRateCalculationInProgress(true);
    const rate = await rateCalculators.calculateRateForTicket(
      ticket,
      currentUser.UserID
    );
    if (isMounted.current) {
      setRateCalculationInProgress(false);
    }

    if (!rate) {
      enqueueSnackbar("Unable to recalculate rate", {
        variant: "error",
        tag: "UnableToRecalculateRate",
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
      return;
    }
    if (isMounted.current) {
      dispatch(feeCalculated({
          rateReceipt: rate,
          rateValidUntil: rate.rateValidUntil,
          totalCost: rate.totalToPay,
          initialTotal: rate.baseFee,
        }
      ));
      onInitialFeeCalculation(rate.totalToPay);
    }
  };

  const calculatePOERate = async (offer) => {
    setRateCalculationInProgress(true);
    const res = await rateCalculators.calculatePOERate(
      currentUser.UserID,
      offer,
      timeZone,
      transactionData.validations,
      transactionData.adjustedFee
    );
    if (isMounted.current) {
      setRateCalculationInProgress(false);
    }
    const { success, reason, reasonPhrase, rate } = res;
    if (!success) {
      enqueueSnackbar(reasonPhrase, {
        variant: "error",
        tag: reason,
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
    } else {
      if (isMounted.current) {
        dispatch(feeCalculated({
            rateReceipt: rate,
            rateValidUntil: rate.rateValidUntil,
            totalCost: rate.totalToPay,
            initialTotal: rate.baseFee,
          }
        ));
        onInitialFeeCalculation(rate.totalToPay);
      }
    }
  };

  const initiateTransaction = async (offer, transactionID) => {
    setApiRequestInProgress(true);
    const startResponse = await startTransaction(offer, transactionID);
    if (isMounted.current) {
      setApiRequestInProgress(false);
      dispatch(startTransactionState( { reference: startResponse.transactionReference }));
    }
  };

  const handleCancel = async () => {
    setApiRequestInProgress(true);
    try {
      await cancelTransaction();
      if (isMounted.current) {
        dispatch(clearTransaction());
        onCancel();
      }
    } catch (err) {
      console.error("Cancel transaction error: ", err);
      enqueueSnackbar("Failed to cancel transaction", {
        variant: "error",
        anchorOrigin: notificationStyle.toastLocation,
        fullwidth: notificationStyle.small,
      });
    } finally {
      if (isMounted.current) {
        setApiRequestInProgress(false);
      }
    }
  };

  const shouldButtonsBeDisabled = apiRequestInProgress || rateCalculationInProgress;
  
  return (
    <div className={classes.step} data-testid={"choose-payment-step"}>
      {loading.isLoading && (
        <>
          <CircularProgress data-testid="choose-payment-loading" />
          <Typography
            variant="h5"
            component="div"
            align="center"
            data-testid="circular-progress-loader"
          >
            {loading.message}
          </Typography>
        </>
      )}
      {!loading.isLoading && (
        <>
          <Typography
            variant="h4"
            component="h1"
            className={clsx("payment-header", classes.header)}
          >
            Payment
          </Typography>
          <Typography
            variant="h4"
            component="div"
            align="center"
            data-testid="totalCost"
            className={clsx("total-cost", classes.totalCost)}
          >{`$${totalCost?.toFixed(2)}`}</Typography>
          <CashieredDeviceCostBreakdown classes={classes} />
          <Button
            data-testid="discounts-choice"
            onClick={onDiscounts}
            disabled={shouldButtonsBeDisabled}
            className={clsx("view-discounts", classes.viewDiscounts)}
          >
            Add Discounts
          </Button>
          {transactionData.selectedOffer?.pricingType?.toLowerCase() === "flatfee" && transactionData.selectedOffer?.useAdjustableFee === true && (
            <Button
              data-testid="adjust-fee"
              onClick={onAdjustFee}
              disabled={shouldButtonsBeDisabled}
              className={clsx("adjust-fee", classes.viewDiscounts)}
            >
              Adjust Fee
            </Button>
          )}
          <div
            className={clsx("payment-type-choice", classes.paymentTypeChoice)}
          >
            <Button
              data-testid="cash-choice"
              onClick={onPayCash}
              disabled={shouldButtonsBeDisabled}
              className={clsx(
                "payment-choice-cash",
                classes.paymentChoiceButton,
                classes.paymentChoiceCash
              )}
            >
              Cash
            </Button>
            <Tooltip
              title={
                noActiveCreditCardTerminal
                  ? "No active credit card terminal"
                  : ""
              }
            >
              <div className={classes.paymentChoiceButtonWrapper}>
                <Button
                  data-testid="credit-choice"
                  onClick={onPayCredit}
                  disabled={noActiveCreditCardTerminal || shouldButtonsBeDisabled}
                  className={clsx(
                    "payment-choice-credit",
                    classes.paymentChoiceButtonCredit
                  )}
                >
                  Credit
                </Button>
              </div>
            </Tooltip>
          </div>
          <Button
            disabled={shouldButtonsBeDisabled}
            onClick={handleCancel}
            className={clsx("cancel-payment", classes.viewDiscounts)}
            data-testid="cancelPayment"
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );
};

CashieredDeviceChoosePaymentType.defaultProps = {
  onPayCash: () => {},
  onPayCredit: () => {},
  onDiscounts: () => {},
  onAdjustFee: () => {},
  onCancel: () => {},
  onInitialFeeCalculation: () => {},
};

CashieredDeviceChoosePaymentType.propTypes = {
  onPayCash: PropTypes.func,
  onPayCredit: PropTypes.func,
  onDiscounts: PropTypes.func,
  onAdjustFee: PropTypes.func,
  onCancel: PropTypes.func,
  onInitialFeeCalculation: PropTypes.func,
};

export default CashieredDeviceChoosePaymentType;
